@import "../../assets/styles/_variables.scss";

// *** This styles are going to be deleted *** //

// Profile Page

.profile {
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
  }

  &__content {
    min-height: calc(100vh - 370px);
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 30%;
    @media (max-width: 991px) {
      width: 100%;
    }
    h2 {
      font-size: 36px;
      margin-bottom: 20px;
      color: #343642;
    }
    h3 {
      font-weight: 500;
      font-size: 24px;
      color: #38417c;
    }
    .head__content {
      max-width: 280px;
      font-size: 18px;
      font-weight: 500;
    }
  }
}

// Symptoms List Page

.symptoms {
  display: flex;
  @media (max-width: 991px) {
    display: block;
  }

  &__content {
    margin-top: 30px;
    min-height: calc(100vh - 370px);
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 370px;
    @media (max-width: 1199px) {
      width: 260px;
    }
    @media (max-width: 991px) {
      width: 100%;
      min-height: auto;
    }
    &:last-child {
      margin-left: 130px;
      width: calc(100% - 370px);
      @media (max-width: 1199px) {
        margin-left: 0;
        width: 100%;
      }
    }
    &__buttons {
      border: 2px solid #38417c;
      border-radius: 8px;
      overflow: hidden;
      margin-bottom: 20px;
      button {
        width: 50%;
        padding: 20px;
        text-align: center;
        letter-spacing: -0.25px;
        font-size: 19px;
        background-color: #fff;
        border: 0px;
        &:first-child {
          border-right: 2px solid #38417c;
        }
      }
    }
    h2 {
      color: #38417c;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 22px;
      letter-spacing: -0.25px;
      margin-top: 0;
      margin-bottom: 5px;
    }
    textarea {
      border: 2px solid #d1d4e3;
      border-radius: 12px;
      height: 200px;
      padding: 20px;
      &:focus {
        outline: none;
      }
    }
    label {
      position: relative;
      padding-left: 30px;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 10px;
      span {
        font-size: 14px;
      }
    }
    [type="checkbox"]:checked,
    [type="checkbox"]:not(:checked),
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type="checkbox"]:not(:checked) + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 2px;
      width: 20px;
      height: 20px;
      background-size: cover;
      background-image: url("../../assets/images/uncheked.svg");
    }
    [type="checkbox"]:checked + label:before,
    [type="radio"]:checked + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 2px;
      width: 20px;
      height: 20px;
      background-size: cover;
      background-image: url("../../assets/images/full-check-icon.svg");
    }
  }
}

// Confirmation

.confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: calc(100vh - 90px);
  width: 100vw;
  .container {
    display: none;
  }
  &__container {
    display: flex;
    @media (max-width: 991px) {
      display: block;
    }
  }

  &__title {
    margin-top: 50px;
    margin-bottom: 80px;
    text-align: center;
    h2 {
      font-size: 55px;
      line-height: 60px;
      margin-top: 15px;
      margin-bottom: 15px;
      color: #38417c;
      @media (max-width: 991px) {
        max-width: 380px;
        margin-left: auto;
        margin-right: auto;
        font-size: 42px;
        line-height: 44px;
      }
    }
    p {
      font-size: 28px;
      line-height: 32px;
      color: #343642;
      margin-top: 0;
      @media (max-width: 991px) {
        font-size: 20px;
        line-height: 23px;
      }
    }
  }

  &__content {
    padding: 20px;
    width: 50%;
    @media (max-width: 991px) {
      width: 100%;
      padding: 0;
      text-align: center;
    }
    &:first-child {
      display: flex;
      justify-content: flex-end;
      @media (max-width: 991px) {
        justify-content: center;
        overflow: hidden;
      }
    }
    &:last-child {
      img {
        margin-right: 15px;
      }
      button {
        height: 65px;
        width: 263px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #38417c;
        background: #38417c;
        font-weight: 500;
        font-size: 24px;
        line-height: 22px;
        border-radius: 12px;
        letter-spacing: -0.25px;
        color: #fff;
        &:hover,
        &:focus {
          background-color: rgba(56, 64, 124, 0.7);
          color: #fff;
          border-color: rgba(56, 64, 124, 0.7);
        }
        @media (max-width: 991px) {
          margin: 0 auto;
        }
      }
    }
    p {
      color: #38417c;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: -0.45px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    h3 {
      color: #343642;
      font-size: 36px;
      line-height: 38px;
      max-width: 300px;
      letter-spacing: -0.9px;
      margin: 0;
      margin-bottom: 50px;
      @media (max-width: 991px) {
        margin: 0 auto;
        margin-bottom: 50px;
      }
    }
  }
}

@import '../../assets/styles/_variables.scss';

.footer {
  height: 90px;

  @media screen and (min-width: 1200px) {
    padding-top: 38px;
    height: 245px;
    border-top: $border_primary_light;
  }

  &__linksWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;

    @media screen and (min-width: 1200px) {
      margin-bottom: 55px;
      height: auto;
    }
  }

  &__prevPageLink,
  &__nextPageLink {
    display: flex;
    align-items: center;
    padding: 0 22px;
    height: 48px;
    font-family: $font_family_secondary;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.25px;
    border-radius: 12px;
    @media(max-width: 767px) {
      padding: 0 12px;
      font-size: 14px;
      height: 38px;
    }
  }

  &__prevPageLink {
    color: $color_blue;
    background-color: $color_white;
    border: $border_primary_middle;
  }

  &__nextPageLink {
    color: $color_white;
    background-color: $color_blue;
  }

  &__prevPageIcon {
    margin-right: 10px;
  }

  &__nextPageIcon {
    margin-left: 10px;
  }

  &__progress_mobile {
    display: block;

    @media screen and (min-width: 1200px) {
      display: none;
    }
  }

  &__progress_desktop {
    display: none;

    @media screen and (min-width: 1200px) {
      display: block;
    }
  }
}

@import '../../assets/styles/_variables.scss';

.header {
  border-bottom: 1px solid $color_light_grey;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 79px;

    @media screen and (min-width: 768px) {
      height: 89px;
    }
  }
}

.languageSelector {
  position: relative;

  &__btn {
    width: 80px;
    color: $color_blue;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    font-weight: 500;
  }

  &__btnText {
    margin-right: 10px;
  }

  &__list {
    position: absolute;
    z-index: 3;
    top: 35px;
    left: 0;
    padding: 0 20px;
    width: 80px;
    background-color: $color_white;
    border-radius: 4px;
    box-shadow: $box_shadow_primary;
  }

  &__listItem {
    display: flex;
    justify-content: center;
    padding: 15px 0;
    border-bottom: $border_primary_light;

    &:last-child {
      border-bottom: none;
    }
  }

  &__listItemBtn {
    @extend .languageSelector__btn;
  }
}

.account {
  display: flex;
  align-items: center;
}

.userImage {
  margin-right: 10px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 3px solid $color_blue;
}

.container {
  display: flex;
  justify-content: space-between;
  @media(max-width: 767px) {
    flex-direction: column;
  }
}

.day {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  margin-right: 35px;
  @media(max-width: 767px) {
    margin-right: 0;
  }
  h4 {
    text-align: center;
    letter-spacing: -0.25px;
    color: #38417c;
    font-size: 24px;
    margin-bottom: 30px;
    margin-top: 0;
    @media(max-width: 767px) {
      margin-bottom: 10px;
    }
  }
  button {
    display: block;
    font-size: 19px;
    border: 2px solid #d1d4e3;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 12px;
    transition: 0.4s;
    background-color: #fff;
    @media(max-width:767px) {
      padding: 10px;
      font-size: 16px;
    }
    &:hover,
    &:focus {
      background: #eef0fc;
      border-color: #38417c;
      color: #38417c;
    }
  }
}

.day:last-child {
  margin-right: 0;
  @media(max-width: 767px) {
    display: none;
  }
}

.page {
  width: 100%;
  min-height: 100%;
}
.container.custom-container {
  @media (max-width: 991px) {
    padding: 0;
  }
}
.container {
  padding: 0 20px;
  width: 100%;
  min-height: 100%;

  @media screen and (min-width: 768px) {
    //
  }

  @media screen and (min-width: 1440px) {
    margin: 0 auto;
    max-width: 1440px;
  }
}

html {
  -webkit-font-smoothing: antialiased;
}
body {
  background: #f9faff;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga", "kern";
  line-height: normal;
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 400;
}
html,
address,
blockquote,
div,
dl,
form,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
table,
ul,
dd,
dt,
li,
tbody,
td,
tfoot,
th,
thead,
tr,
button,
del,
ins,
map,
object,
a,
abbr,
acronym,
b,
bdo,
big,
br,
cite,
code,
dfn,
em,
i,
img,
kbd,
q,
samp,
small,
span,
strong,
sub,
sup,
tt,
var,
legend,
fieldset,
p,
hr,
iframe {
  margin: 0;
  padding: 0;
  border: none;
  list-style: none;
}
a,
input,
select,
textarea {
  outline: none;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  line-height: 1.4;
  margin: 0px;
  padding: 0px;
}
a {
  text-decoration: none;
  color: #000;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
}
a:hover {
  text-decoration: none;
}
a:focus,
input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none;
  text-decoration: none;
  color: #000;
}
img {
  max-width: 100%;
  vertical-align: middle;
  border: none;
  outline: none;
  border-radius: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
  margin: 0;
  padding: 0;
}
input {
  resize: none;
  border: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select::-ms-expand {
  display: none;
}
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
}
select,
input[type="submit"] {
  cursor: pointer;
}
p {
  margin: 0px;
  padding: 0px;
  line-height: 1.4;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

html {
  // scroll-behavior: smooth !important;
}
.royal {
  color: #38417c !important;
}
/*** meet page css start ***/
@font-face {
  font-family: "ProximaSoft-Regular";
  src: url("../fonts/ProximaSoft-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/ProximaSoft-Regular.otf") format("opentype"),
    url("../fonts/ProximaSoft-Regular.woff") format("woff"),
    url("../fonts/ProximaSoft-Regular.ttf") format("truetype"),
    url("../fonts/ProximaSoft-Regular.svg#ProximaSoft-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "EuclidFlex-Medium";
  src: url("../fonts/EuclidFlex-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/EuclidFlex-Medium.otf") format("opentype"),
    url("../fonts/EuclidFlex-Medium.woff") format("woff"),
    url("../fonts/EuclidFlex-Medium.ttf") format("truetype"),
    url("../fonts/EuclidFlex-Medium.svg#EuclidFlex-Medium") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "EuclidFlex-Regular";
  src: url("../fonts/EuclidFlex-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/EuclidFlex-Regular.otf") format("opentype"),
    url("../fonts/EuclidFlex-Regular.woff") format("woff"),
    url("../fonts/EuclidFlex-Regular.ttf") format("truetype"),
    url("../fonts/EuclidFlex-Regular.svg#EuclidFlex-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
html {
  height: 100%;
}
body {
  font-family: "ProximaSoft-Regular";
}
button:focus {
  outline: none;
}

p {
  margin-bottom: 0;
}
ul {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

@font-face {
  font-family: "EuclidFlexMedium";
  src: url("../fonts/EuclidFlexMedium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/EuclidFlexMedium.otf") format("opentype"),
    url("../fonts/EuclidFlexMedium.woff") format("woff"),
    url("../fonts/EuclidFlexMedium.ttf") format("truetype"),
    url("../fonts/EuclidFlexMedium.svg#EuclidFlexMedium") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Regular.eot");
  src: url("../fonts/ProximaNova-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/ProximaNova-Regular.woff2") format("woff2"),
    url("../fonts/ProximaNova-Regular.woff") format("woff"),
    url("../fonts/ProximaNova-Regular.ttf") format("truetype"),
    url("../fonts/ProximaNova-Regular.svg#ProximaNova-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
nav.navbar.navbar-expand-lg {
  padding: 0;
}
.menu_wrapper {
  padding: 25px 0;
  height: 90px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(56, 65, 124, 0.2);
  background: #fff;
  position: relative;
  z-index: 999;
}
img.desktop-logo {
  display: block;
}
img.mobile-logo {
  display: none;
}
h1 {
  font-family: "EuclidFlexMedium";
  font-size: 60px;
  line-height: 60px;
  letter-spacing: -1.5px;
  color: #343642;
  margin-bottom: 22px;
  font-weight: normal;
}
.registration-page h1 {
  margin-bottom: 45px;
}
.register-form {
  margin-top: 30px;
}
h2 {
  font-size: 24px;
  line-height: 23px;
  letter-spacing: -0.6px;
  color: rgba(56, 65, 124, 0.7);
  font-weight: normal;
  margin-bottom: 60px;
}
h3 {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.45px;
  color: rgba(56, 65, 124, 0.7);
  font-weight: 500;
  margin-bottom: 10px;
}
h4 {
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: -0.325px;
  color: rgba(56, 65, 124, 0.3);
}
.img-wrppaer {
  text-align: right;
  position: fixed;
  right: 0;
  bottom: 0;
}
.main-bg-sectoin {
  padding: 120px 0 0 0;
}
p {
  font-size: 17px;
  line-height: 19px;
  letter-spacing: -0.425px;
  font-weight: normal;
  color: rgba(56, 65, 124, 0.7);
  margin: 15px 0 0 0;
  font-weight: 500;
}
a {
  color: #38417c;
}
.form-group.value-group {
  position: relative;
  max-width: 285px;
  width: 100%;
}
.form-group .form-control {
  background: #ffffff;
  border: 1px solid #38417c;
  box-shadow: 0px 2px 16px rgba(39, 40, 42, 0.118608);
  border-radius: 8px;
  height: 55px;
  position: relative;
  padding-left: 20px;
  font-weight: 500;
}
.sign-circle {
  width: 24px;
  height: 24px;
  background: #747aa3;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  position: absolute;
  right: 15px;
  top: 15px;
}
.menu_wrapper .drop-down .selected a {
  display: block;
  text-decoration: none;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.menu_wrapper .drop-down .selected a span {
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  vertical-align: middle;
  font-weight: 600;
  color: #38417c;
  background: url(../images/arrow.svg) no-repeat right;
  padding-right: 28px;
}
.menu_wrapper .drop-down .selected a i {
  color: #9fa3b1;
  padding-left: 10px;
  font-size: 24px;
  vertical-align: middle;
}
.menu_wrapper .drop-down .option {
  position: relative;
}

.menu_wrapper .drop-down .options ul {
  display: none;
  list-style: none;
  padding: 0px 0px;
  position: absolute;
  left: 0px;
  top: 20px;
  margin-top: 15px;
  width: 100%;
  max-width: 205px;
  background: #ffffff;
  border: 1px solid rgba(56, 65, 124, 0.17);
  border-radius: 8px;
  box-shadow: 0px 0px 6px 2px #f0f0f0;
  z-index: 9;
}
.menu_wrapper .drop-down .selected span.value,
.menu_wrapper .drop-down .options span.value {
  display: none;
}
.menu_wrapper .drop-down .options ul li {
  padding: 0 10px;
}
.menu_wrapper .drop-down .options ul li a {
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase; /* padding: 14px 5px; */
  color: rgba(56, 65, 124, 0.8);
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid rgba(56, 65, 124, 0.17);
  padding-bottom: 15px;
  padding-top: 15px;
  padding-left: 15px;
}
.menu_wrapper .drop-down .options ul li:last-child a {
  border-bottom: none;
}
/*.drop-down .options ul li a:hover{
  background:#3179ac;
  color:#fff;
  transition:0.2s ease;
}*/

form input::-webkit-input-placeholder {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.45px;
  color: #38417c;
}
form input::-moz-placeholder {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.45px;
  color: #38417c;
}
form input::-ms-input-placeholder {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.45px;
  color: #38417c;
}
form input::-moz-placeholder {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.45px;
  color: #38417c;
}
p.desktop-p {
  display: none;
}
.d-show {
  display: block;
}
.primary-btn {
  font-size: 24px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.6px;
  color: #ffffff;
  background-color: #38417c;
  max-width: 379px;
  width: 100%;
  display: inline-block;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  transition: 0.5s;
  border: 1px solid transparent;
}
.primary-btn:hover,
.primary-btn:focus {
  background-color: transparent;
  color: #38417c;
  border: 1px solid #38417c;
}

/*desktop-page-css*/
#site-topnav {
  padding: 0;
}
#site-topnav .navbar-toggle {
  background-color: transparent;
}
#site-topnav .navbar-toggle .menu {
  font-size: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgba(56, 65, 124, 0.84);
  cursor: pointer;
}
#site-topnav .navbar-toggle .menu img {
  margin-left: 10px;
}
.navbar-collapse {
  position: absolute;
  width: 100%;
  top: 100%;
  right: 0px;
  max-width: 300px;
  z-index: 11;
}
.user-detail-popup {
  background-color: #fff;
  box-shadow: 0px 0px 6px 2px #f0f0f0;
  border-radius: 4px;
  z-index: 1;
  border: 1px solid rgba(56, 65, 124, 0.17);
}
.user-detail-popup li a {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgba(56, 65, 124, 0.8) !important;
  padding: 20px;
  display: inline-block;
  width: 100%;
}
.user-detail-popup li {
  border-top: 1px solid rgba(56, 65, 124, 0.17);
  margin: 0 20px;
}
.user-detail-popup li:first-child {
  border-top: 1px solid transparent;
  display: flex;
}
.user-detail-popup li:first-child h2 {
  margin-bottom: 0;
  color: #38417c;
  padding-left: 20px;
  margin-top: 20px;
}
.user-detail-popup li:first-child a {
  padding: 20px 0 20px 0;
  width: auto;
}
li.nav-item a {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.content-row [class*="col-"] {
  margin-bottom: 34px;
}
.box-wrapper h2 {
  color: #38417c;
  margin-bottom: 15px;
  margin-top: 20px;
}
.box-wrapper small {
  font-size: 18px;
  line-height: 18px;
  color: rgba(56, 65, 124, 0.7);
  font-weight: normal;
}
.box-wrapper {
  background: #ffffff;
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 25px;
  transition: 0.5s;
  box-shadow: 0px 2px 14px rgba(56, 65, 124, 0.17);
  height: 100%;
}
.box-wrapper.text-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.box-wrapper .circle {
  border: 2px solid rgba(56, 65, 124, 0.7);
  width: 23px;
  height: 23px;
  display: inline-block;
  border-radius: 100%;
  font-size: 21px;
  font-weight: 700;
  color: #38417c;
  line-height: 19px;
  text-align: center;
}
.box-wrapper:hover {
  border: 1px solid #38417c;
}
.goodmorning-text {
  max-width: 250px;
  width: 100%;
}
.content-wrapper {
  display: flex;
  align-items: center;
  padding-left: 166px;
  margin-right: 10px;
  position: relative;
  z-index: 9;
}
.inner-desktop-content {
  display: flex;
  position: relative;
  z-index: 1;
}
.circle-img-wrppaer {
  position: absolute;
  right: 0;
  top: 162px;
}
.first-row {
  height: 100%;
}
.box-img img {
  border-radius: 50px;
}

/*.box-wrapper:nth-child(4) h2 {
  color: #747aa3;
}*/

/* Registration Desktop css*/
.register-form .fild-row {
  margin-bottom: 13px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  position: relative;
}
.fild-row .drop-down {
  border: 1px solid #bfc2d6;
  border-radius: 8px;
  background: #ffffff;
}

.fild-row .selected a {
  display: block;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  padding: 25px 15px;
  position: relative;
  background: url(../images/select-arrow.svg) no-repeat 96%;
  width: 100%;
  color: #73799f;
}
.fild-row .open-list .selected a {
  background: url(../images/drop-arrow-open.svg) no-repeat 96%;
  color: #38417c;
}
.fild-row .options ul li a:hover {
  background-color: #f8f8fd;
}
.fild-row .form-control:focus::placeholder {
  color: #38417c;
}
.fild-row .drop-down.open-list {
  border: 1px solid #38417c;
  box-shadow: 0px 0px 6px 2px #f0f0f0;
  position: relative;
}
.fild-row .selected a span {
  width: 100%;
  display: block;
}
.fild-row .selected a i {
  position: absolute;
  right: 20px;
  top: 20px;
}
/* .fild-row .options{
    position: absolute;
    top: 100%;
} */
.fild-row .options ul {
  display: none;
}
.fild-row .options ul li a {
  display: block;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  padding: 19px;
  border-top: 1px solid #d2d4de;
}
.register-form .fild-row label {
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: rgba(56, 65, 124, 0.5);
  padding: 5px 20px 0;
  margin-bottom: -22px;
  display: block;
}
.register-form .fild-row:focus label {
  color: #38417c;
}
.register-form .fild-row .half {
  width: 47.5%;
  float: left;
  margin-left: 5%;
}
.register-form .fild-row .half:first-child {
  margin-left: 0;
}
.register-form .fild-row .half .form-control {
  text-align: center;
}
.register-form .fild-row .form-control {
  background: #ffffff;
  border: 1px solid #d2d4de;
  border-radius: 8px;
  height: 56px;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: rgba(56, 65, 124, 0.5);
  padding: 20px;
}
.register-form .fild-row .form-control:focus {
  color: #38417c;
  border: 1px solid #38417c;
}
.register-form .fild-row.label .form-control {
  padding: 20px 20px 10px 20px;
}

.fild-row .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #38417c;
}
.fild-row .form-control::placeholder {
  color: rgba(56, 65, 124, 0.5);
}
.fild-row .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #38417c;
}
.fild-row .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #38417c;
}
.fild-row .primary-btn {
  max-width: 100%;
}
/*add-office-desktop*/

.main-bg-sectoin.add-office-page h1 {
  width: 100%;
}
.main-bg-sectoin.add-office-page .form-group .form-control {
  height: 64px;
  padding: 21px 51px 21px 21px;
}
.main-bg-sectoin.add-office-page .sign-circle {
  top: 20px;
}
.main-bg-sectoin.add-office-page h2 {
  margin-bottom: 50px;
}
a.secondary-btn {
  max-width: 183px;
  width: 100%;
  height: 64px;
  font-size: 24px;
  line-height: 23px;
  letter-spacing: -0.6px;
  background-color: rgba(56, 65, 124, 0.5);
  color: #fff;
  display: inline-block;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  border: 1px solid rgba(56, 65, 124, 0.2);
}
a.secondary-btn:hover {
  background-color: transparent;
  color: #747aa3;
  border: 1px solid #747aa3;
}
.add-office-form {
  display: flex;
  margin: 50px 0 0 0;
}
.main-bg-sectoin.add-office-page a.secondary-btn {
  margin-left: 20px;
}
span.arrow-circle {
  height: 20px;
  width: 20px;
  background-color: #38417c;
  border-radius: 50px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cancel-wrapper {
  display: inline-flex;
  align-items: center;
  margin-bottom: 50px;
}
.cancel-wrapper .arrow_icon {
  color: #38417c;
  font-size: 20px;
  display: inline-block;
  vertical-align: bottom;
}
.cancel-wrapper a {
  margin: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 18px;
  color: #38417c;
  font-family: "EuclidFlexMedium";
}

.cancel-wrapper a:hover,
.cancel-wrapper a:hover .arrow_icon {
  color: #747aa3;
}
.man_img {
  display: none;
}

/*** added css ***/
.form-control:focus {
  box-shadow: none;
}
.options ul li {
  text-align: left;
}
.drop-down-wrpper {
  position: relative;
  width: 104px;
  text-align: right;
}
.register-form .drop-down:focus {
  border: 1px solid #38417c;
}
.register-form button[type="submit"] {
  cursor: pointer;
  margin-top: 7px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  box-shadow: 0px 2px 16px rgba(39, 40, 42, 0.118608);
}
.main-bg-sectoin .col-lg-4 {
  padding-right: 7px;
}
.mobile-leaves {
  display: none;
}
.navbar-brand {
  max-width: 157px;
  padding: 0;
}
.goodmorning-text h2 {
  color: rgba(56, 65, 124, 0.7);
  max-width: 200px;
}
.content-row .col-6 {
  padding: 0 10px;
}
.box-img {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  position: relative;
}
.box-img img {
  border-radius: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.add-new-office h2 {
  color: rgba(56, 65, 124, 0.7);
}
.dots {
  height: 14px;
  width: 14px;
  border: 2px solid #fff;
  position: absolute;
  right: 0;
  bottom: 4px;
  background: #5ad4bb;
  border-radius: 100%;
}
.add-new-office-main p {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: rgba(56, 65, 124, 0.7);
}
.add-office-form .form-control::placeholder {
  color: rgba(56, 65, 124, 0.5);
}
.add-office-form .form-control::placeholder,
.add-office-form .form-control {
  color: rgba(56, 65, 124, 0.5);
  font-size: 18px;
  font-weight: 500;
}
.office-home-left {
  min-width: 410px;
}
/*** office home page css start ***/
.office-home-left .box-img {
  width: 120px;
  height: 120px;
  margin-bottom: 30px;
}
.office-home-left .dots {
  height: 23px;
  width: 23px;
}
.office-info h2 {
  color: #38417c;
}
.office-info h2 span {
  display: block;
}
.office-home-left h2 {
  margin-bottom: 40px;
}
.navbar-toggler {
  padding-right: 0;
}
.dot .offline .profile:before {
  background: #ff5959;
}
.office-icons {
  height: 72px;
  width: 72px;
  border-radius: 12px;
  float: left;
  text-align: center;
  line-height: 72px;
  position: relative;
}
.yellow-sqr {
  background: #ffc700;
}
.green-sqr {
  background: #5ad4bb;
}
.pink-sqr {
  background: #d45a8d;
}
.office-home-info {
  display: table;
  padding-left: 22px;
}
.office-home-info h2 {
  color: #343642;
  font-size: 36px;
  line-height: 38px;
  letter-spacing: -0.9px;
  margin: 0 0 0px 0;
  font-family: "EuclidFlexMedium";
}
.office-home-info p {
  font-size: 19px;
  line-height: 23px;
  letter-spacing: -0.25px;
  font-weight: 500;
  margin-top: 12px;
}
.office-home-info-right {
  transition: 0.4s;
}
.office-home-right .box-wrapper:hover .office-home-info-right,
.office-home-right .box-wrapper:focus .office-home-info-right,
.office-home-right .box-wrapper .office-home-info-right.active {
  opacity: 1;
}
.office-home-right .box-wrapper {
  padding: 30px;
  border: 0;
}
.office-home-right .box-wrapper:hover,
.office-home-right .box-wrapper:focus {
  border: 0;
}
.counter {
  height: 24px;
  width: 24px;
  background: #ff5959;
  font-size: 13px;
  letter-spacing: -0.325px;
  border-radius: 100%;
  color: #fff !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 25px;
}
.office-home-right .content-row [class*="col-"] {
  margin-bottom: 20px;
}
.content-wrapper.office-home-right {
  display: block;
}
.main-bg-sectoin.less-space {
  position: relative;
  z-index: 9;
}
/*** triage appointment page css start ***/
.appointment-box {
  background: #fcfcff;
  box-shadow: 0px 2px 14px rgba(39, 40, 42, 0.17);
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  min-height: 386px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.schedule-appointment {
  background: #ffffff;
  border: 1px solid rgba(56, 65, 124, 0.2);
  border-radius: 8px;
  padding: 13px;
}
.triage-app-left {
  max-width: 488px;
}
.content-wrapper.triage-app-right {
  display: block;
  width: 100%;
  padding: 77px 0 0 57px;
  margin-right: 0;
}
.walkin-appointment {
  margin: 20px 0 0 0;
}
.walkin-appointment .primary-btn {
  max-width: 100%;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.45px;
  line-height: 63px;
}
.appointment-box h3 {
  color: rgba(56, 65, 124, 0.4);
  font-weight: bold;
  margin-bottom: 0;
}
.appointment-box h4 {
  color: rgba(56, 65, 124, 0.4);
  font-weight: bold;
  margin-bottom: 0;
}
.next-step {
  margin-top: 20px;
  display: -mozflex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: right;
  -webkit-justify-content: right;
  -ms-justify-content: right;
  justify-content: right;
}
.next-step .primary-btn {
  display: inline-block;
  vertical-align: middle;
  line-height: 64px;
  text-transform: capitalize;
  box-shadow: 0px 2px 16px rgba(39, 40, 42, 0.118608);
  max-width: 285px;
}
.next-step .primary-btn.small {
  max-width: 184px;
}
/*** triage symptoms page css start ***/
/*** custom checkbox button css start ***/
.checkbox-custom {
  display: none;
}
.checkbox-custom-label {
  position: relative;
  cursor: pointer;
  line-height: 21px;
  display: inline-block;
  color: #38417c;
  font-size: 18px;
  line-height: 18px;
  vertical-align: middle;
  letter-spacing: -0.45px;
  font-weight: bold;
}
.checkbox-custom + .checkbox-custom-label::before {
  content: "";
  border: 2px solid rgba(56, 65, 124, 0.9);
  width: 24px;
  height: 24px;
  margin-right: 15px;
  text-align: center;
  border-radius: 100%;
  float: left;
}
.checkbox-custom:checked + .checkbox-custom-label::after {
  content: "";
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(56, 65, 124, 0.9) url(../images/check.svg) no-repeat center
    center;
  width: 24px;
  height: 25px;
  border-radius: 100%;
}
.checkbox-custom-label span {
  padding: 2px 0 0 0;
  display: table;
}
/*** custom checkbox button css end ***/
.symptoms-listing {
  float: left;
  width: 50%;
  text-align: left;
  padding-right: 40px;
}
.symptoms-listing li {
  margin-top: 13px;
}
.symptoms-listing li:first-child {
  margin-top: 0;
}
/*** traige details page css start ***/
.triage-details .form-control {
  font-weight: 500;
  font-size: 19px;
  line-height: 21px;
  letter-spacing: -0.25px;
  color: #38417c;
  min-height: 242px;
  padding: 20px;
  resize: none;
  border: 1px solid rgba(56, 65, 124, 0.2);
  border-radius: 8px;
}
.word-limit {
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  color: rgba(56, 65, 124, 0.5);
  text-align: right;
  letter-spacing: -0.375px;
  padding: 10px 0 0;
}
.triage-details-left {
  max-width: 422px;
}
.triage-details-main .triage-app-right {
  padding-left: 208px;
}
.account-sign-register {
  font-weight: 500;
  font-size: 17px;
  line-height: 19px;
  letter-spacing: -0.425px;
  color: rgba(56, 65, 124, 0.7);
}
.account-sign-register a {
  color: #38417c;
}
.account-sign-register a:hover,
.account-sign-register a:focus {
  color: rgba(56, 65, 124, 0.7);
}
/*** list-of-desktop page css start ***/
.content-wrapper.list-desktop-right {
  display: block;
  width: 100%;
  padding: 77px 0 0 57px;
  margin-right: 0;
}
.accordion {
  padding: 35px 0;
}
.accordion .card {
  padding: 25px;
  box-shadow: 0px 0px 14px rgba(56, 65, 124, 0.17);
  background: #ffffff;
  border-radius: 8px !important;
  margin-bottom: 20px;
  border: 1px solid transparent !important;
}
.accordion .card-header {
  padding: 0;
  background-color: transparent;
  border: 0;
}
.accordion .card-header .card-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}
/* .accordion .card-header .card-wrapper .rotate-icon {
  
  color: rgba(55, 64, 123, 0.17);
  font-size: 22px;
  background: url(../images/select-arrow.svg) no-repeat;
} */
.accordion .card-header > a {
  background: url(../images/select-arrow-blue.svg) no-repeat right;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.accordion .card-header a.collapsed {
  background: url(../images/select-arrow.svg) no-repeat right;
}
.accordion h2 {
  margin-bottom: 5px;
  font-size: 24px;
  line-height: 23px;
  font-weight: 700;
  color: #38417c;
  font-weight: semibold;
}
.accordion small {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: rgba(56, 65, 124, 0.7);
  margin-bottom: 10px;
  display: block;
}

.accordion .card-header .box-profile {
  margin-left: 30px;
}
.accordion .card-body {
  padding: 40px 0 0;
}
.accordion .card-body ul {
  display: flex;
  margin-bottom: 30px;
}
.accordion .card-body ul li {
  padding: 0 30px 0 0;
}
.accordion .card-body button {
  max-width: 100%;
  cursor: pointer;
}
.modal-open .modal {
  padding-left: 17px;
}
.modal-dialog {
  max-width: 322px;
}
.modal-content .modal-header {
  padding: 20px 20px 5px;
  border: 0;
}
.modal-content .modal-footer {
  border: 0;
  padding: 0 20px 20px;
}
.modal-content .modal-header .modal-title {
  color: #38417c;
  font-size: 24px;
  line-height: 23px;
  font-weight: 700;
}
.modal-content .modal-body {
  color: #38417c;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  padding: 0 20px 10px;
}
.modal-content .modal-footer button {
  margin-bottom: 0;
  height: 38px;
  font-size: 13px;
  line-height: 17px;
}
.list-dotors-right .primary-btn {
  box-shadow: 0px 2px 16px rgba(39, 40, 42, 0.118608);
}
.accordion .card.open-accordion {
  border: 1px solid #38417c !important;
}
.modal-open {
  background: rgba(33, 57, 73, 0.85);
}
.modal-footer .btn {
  width: 50%;
  border-radius: 8px;
}
.modal-footer .secondary-btn {
  background: transparent;
  border: 1px solid #38417c;
}
.modal-footer .secondary-btn:hover,
.modal-footer .secondary-btn:focus {
  background: #38417c;
  color: #fff;
}
/*** waiting room page css start ***/
.waiting-room-left .dots {
  height: 12px;
  width: 12px;
}
.waiting-room-left .box-img {
  width: 56px;
  height: 56px;
}
.waiting-room-left h1 {
  font-size: 36px;
  line-height: 36px;
  margin-top: 20px;
}
.waiting-room-left {
  max-width: 281px;
}
.waiting-room-right-main {
  padding: 65px 0 0 0;
}
.dots.orange {
  background: #ffc700;
}
/*** waiting room ready page css start ***/
.waiting-room-main {
  padding-bottom: 90px;
}
.waiting-room-left .primary-btn {
  box-shadow: 0px 2px 16px rgba(39, 40, 42, 0.118608);
  height: 56px;
  max-width: 280px;
  cursor: pointer;
}
.waiting-room-left h2 {
  margin-bottom: 30px;
}
.waiting-room-top-sec {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.waiting-main-img {
  float: left;
  width: 50%;
  padding: 0 10px 0 0;
}
.waiting-content {
  width: 50%;
  padding-left: 10px;
  float: left;
}
.waiting-content > img,
.video-setting > img,
.waiting-main-img > img {
  width: 100%;
}
.no-padding {
  padding: 0;
}
.incall-middle,
.incall-last {
  float: left;
  width: 50%;
}
.incall-mobile > img {
  width: 100%;
}
.man-img {
  max-width: 626px;
  width: 100%;
}
/* .triage-app-right .next-step  {
    display: block !important;
} */
.options ul {
  position: absolute;
  width: calc(100% + 2px);
  background: #fff;
  left: -1px;
  top: 51px;
  z-index: 9;
  border: 1px solid #38417c;
  border-top: 0;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 0px 6px 2px #f0f0f0;
}
.fild-row.gender .half {
  position: relative;
}
.gender label {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  right: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 18px !important;
  line-height: 50px !important;
  cursor: pointer;
}
.gender input[type="radio"]:checked + label {
  color: #38417c;
}
/*** waititng room added css ***/
.waiting-main-img {
  float: left;
  width: 50%;
  padding: 0 10px 0 0;
}
.waiting-content {
  width: 50%;
  padding-left: 10px;
  background: #fcfcff;
  border-radius: 14px;
  box-shadow: 0px 2px 14px rgba(39, 40, 42, 0.17);
  float: left;
  padding: 40px;
  position: relative;
}
.waiting-room-top-sec {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.chat-text:nth-child(even) .chat-box {
  background: #38417c;
  max-width: 87%;
  margin-left: auto;
  margin-right: 0;
}
.chat-text h3 {
  color: #343642;
  text-align: right;
  font-weight: Semibold;
  display: inline-block;
  vertical-align: middle;
  padding: 0 10px;
  font-size: 19px;
  line-height: 23px;
}
.chat-user {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  display: inline-block;
  vertical-align: top;
  margin-top: -4px;
}
.chat-info {
  display: inline-block;
  width: 100%;
  text-align: left;
}
.chat-box {
  padding: 15px;
  border-radius: 8px;
  background: rgba(56, 65, 124, 0.0967);
}
.chat-box p {
  color: #38417c;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.38px;
  margin: 0 0 13px 0;
}
.chat-time {
  font-size: 13px;
  letter-spacing: -0.32px;
  color: rgba(56, 65, 124, 0.7);
}
.chat-text:nth-child(2n) .chat-info {
  text-align: right;
  padding-top: 25px;
  border-top: 1px solid #d1d4e3;
}
.chat-text:nth-child(2n) .chat-user {
  float: right;
}
.chat-text {
  margin-top: 10px;
}
.chat-text:first-child {
  margin-top: 0;
}
.type-msg .common-input {
  border: 1px solid rgba(56, 65, 124, 0.17);
  font-size: 15px;
  border-radius: 8px;
  font-weight: 500;
  height: 59px;
  width: 100%;
  padding: 20px;
  color: rgba(56, 65, 124, 0.5);
}
.type-msg .common-input:focus {
  border: 1px solid #38417c;
}
.type-msg {
  position: absolute;
  bottom: 40px;
  width: calc(100% - 80px);
  left: 40px;
}
.chat-text-main {
  padding-bottom: 60px;
}
.video-setting {
  background: #fcfcff;
  box-shadow: 0px 2px 14px rgba(56, 65, 124, 0.17);
  border-radius: 8px;
  padding: 40px;
  margin: 20px 0 0 0;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  text-align: left;
}
.video-setting h2 {
  font-size: 36px;
  line-height: 38px;
  color: #38417c;
  letter-spacing: -0.9px;
  margin-bottom: 20px;
}
.source h4 {
  color: rgba(56, 65, 124, 0.7);
  margin-bottom: 7px;
}
.source select {
  background: rgba(200, 203, 224, 0.3) url(../images/arw.svg) no-repeat 95%;
  border-radius: 5px;
  border: 0;
  font-size: 18px;
  line-height: 16px;
  color: #38417c;
  font-weight: bold;
  padding: 12px;
  width: 100%;
  font-family: "ProximaSoft-Regular";
}
.source select option {
  font-family: "ProximaSoft-Regular";
}
.source-main {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.source-main {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.source.left-s {
  padding-right: 10px;
}
.source.right-s {
  padding-left: 10px;
}
.source {
  float: left;
  width: 50%;
}
.vol-range .vol {
  float: left;
  color: #737aa3;
  font-size: 9px;
  line-height: 17px;
  margin: 5px 0 0 0;
}
.vol-range .vol:last-child {
  float: right;
}
.vol-info h4 {
  float: left;
  margin: 6px 0 0 0;
}
.speaker-vol span {
  background: url(../images/speaker.svg) no-repeat;
  padding-left: 10px;
}
.speaker-vol {
  background: #38417c;
  border-radius: 5px;
  float: right;
  color: #fff;
  font-size: 9px;
  line-height: 17px;
  text-align: center;
  width: 81px;
  height: 22px;
  line-height: 23px;
}
.speaker-vol:hover,
.speaker-vol:focus {
  background: rgba(56, 65, 124, 0.7);
  color: #fff;
}
.player {
  display: inline-block;
  vertical-align: middle;
  width: 232px;
  margin: 30px 0 0 0;
}
.vol-info {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin: 0px 0 20px 0;
}
.micro-vol {
  background: url(../images/micro.png) no-repeat;
  width: 47px;
  height: 33px;
  display: block;
  float: right;
  background-size: 47px;
}
.microphone-l span {
  left: 148px;
}
.close-chat {
  background: rgba(56, 65, 124, 0.14) url(../images/close.svg) no-repeat center
    center;
  border-radius: 100%;
  width: 59px;
  height: 59px;
  float: right;
}
.type-msg.with-close form {
  width: calc(100% - 80px);
  float: left;
}
.incall-middle {
  padding-right: 15px;
}
.notifc {
  background: #ff7259;
  width: 17px;
  height: 17px;
  color: #fff;
  font-size: 13px;
  text-align: center;
  line-height: 18px;
  position: absolute;
  border-radius: 100%;
  right: 7px;
  top: 10px;
  font-style: normal;
}
.tabbing-sec .nav-item {
  position: relative;
  display: inline-block;
}
.tabbing-sec .nav-item .o-tooltip {
  position: absolute;
  z-index: 999;
  background: transparent;
  width: 100%;
  left: 0;
  border-radius: 100%;
  top: 0;
  height: 50px;
  cursor: pointer;
}
.tooltips {
  opacity: 0;
  background: #737aa3;
  box-shadow: 0px 2px 14px rgba(39, 40, 42, 0.17);
  border-radius: 8px;
  position: absolute;
  top: -90px;
  left: 0;
  width: 215px;
  font-size: 18px;
  color: #fff;
  letter-spacing: -0.45px;
  text-align: center;
  padding: 13px;
  line-height: 18px;
}
.tooltips::after {
  position: absolute;
  left: 16px;
  bottom: -18px;
  border-top: 9px solid #737aa3;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid transparent;
  content: "";
}
.open-tooltip .tooltips {
  opacity: 1;
}
.tabbing-sec .nav-item.open-tooltip .o-tooltip {
  display: none;
}
.nav-item.open-tooltip .nav-item {
  z-index: 9999;
}
.flip-tab .tooltips {
  left: -100px;
}
.flip-tab .tooltips::after {
  left: 50%;
}
.msg-tab .tooltips {
  left: auto;
  right: 0;
}
.msg-tab .tooltips::after {
  left: auto;
  right: 15px;
}
.tabbing-sec .nav-link.close-links {
  background: #ff7259;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}
.incallmobile .tabbing-sec .nav-item {
  margin-left: 23px;
}
.slider {
  -webkit-appearance: none;
  height: 2px;
  width: 232px;
  background: rgba(115, 122, 163, 0.4);
  position: relative;
  outline: none;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 20px;
  background: #38417c;
  position: absolute;
  cursor: pointer;
  outline: none;
  cursor: pointer;
  border: 0;
}

.slider::-moz-range-thumb {
  width: 12px;
  height: 12px;
  border-radius: 20px;
  background: #38417c;
  position: absolute;
  cursor: pointer;
  outline: none;
  cursor: pointer;
  border: 0;
}
/***  range slider css ***/
input[type="range"] {
  /*removes default webkit styles*/
  -webkit-appearance: none;

  /*fix for FF unable to apply focus style bug */
  border: 1px solid white;

  /*required for proper track sizing in FF*/
  width: 232px;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 232px;
  height: 2px;
  background: rgba(115, 122, 163, 0.4);
  border: none;
  border-radius: 3px;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #38417c;
  margin-top: -5px;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #ccc;
}

input[type="range"]::-moz-range-track {
  width: 232px;
  height: 2px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}
input[type="range"]::-moz-range-thumb {
  border: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #38417c;
}

/*hide the outline behind the border*/
input[type="range"]:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}

input[type="range"]::-ms-track {
  width: 232px;
  height: 2px;

  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;

  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;

  /*remove default tick marks*/
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #777;
  border-radius: 10px;
}
input[type="range"]::-ms-fill-upper {
  background: #ddd;
  border-radius: 10px;
}
input[type="range"]::-ms-thumb {
  border: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #38417c;
}

/*version 2*/
/*** office home.inbox page css start ***/
.office-home-right .box-wrapper {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.close-chat.submit-chat {
  background: #38417c url(../images/submit.png) no-repeat center center;
}
.chat-window h2 {
  float: left;
  width: calc(100% - 40px);
  color: #38417c;
  margin: 0;
}
.chat-toggles {
  position: relative;
  float: right;
}
.chat-options {
  position: absolute;
  top: 100%;
  right: -3px;
  background: #ffffff;
  border: 1px solid rgba(56, 65, 124, 0.17);
  box-shadow: 0px 0px 14px rgba(56, 65, 124, 0.17);
  border-radius: 8px;
  width: 185px;
  padding: 7px;
  z-index: 9;
  margin-top: 7px;
}
.chat-options li a {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgba(56, 65, 124, 0.8);
  padding: 10px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.chat-options li {
  border-top: 1px solid rgba(56, 65, 124, 0.17);
}
.chat-options li:first-child {
  border-top: 0;
}
.chat-options li a:hover,
.chat-options li a:focus {
  color: rgba(35, 39, 70, 1);
}
.chat-window {
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 2px 14px rgba(39, 40, 42, 0.17);
  border-radius: 8px;
}
.inbox-right {
  padding: 80px 30px 100px 140px;
  position: relative;
  z-index: 9;
}
.new-msg {
  padding-right: 15px;
}
.chat-window .chat-box {
  background: #eef0fc;
  max-width: 87%;
  margin-right: auto;
}
.chat-window .chat-box p {
  color: #343642;
}
.chat-time {
  color: #73799f;
  text-align: left;
  line-height: 22px;
  margin-top: 3px;
}
.chat-text:nth-child(2n) .chat-box p {
  color: #ffffff;
}
.chat-text:nth-child(2n) .chat-time {
  color: #73799f;
  text-align: right;
  display: block;
}
.new-msg-info h3 {
  color: #000;
  font-weight: bold;
}
.new-msg-info p {
  font-size: 15px;
  line-height: 17px;
  margin-top: 10px;
}
.new-msg-info {
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(56, 65, 124, 0.2);
  border-radius: 12px;
  padding: 20px 23px 20px 20px;
  transition: 0.4s;
  border: 2px solid transparent;
  margin-top: 12px;
  cursor: pointer;
  text-align: left;
}
.new-msg-info:last-child {
  margin-bottom: 12px;
}
.new-msg-info:first-child {
  margin-top: 0;
}
/*.new-msg-info.new-msg-dots {
  padding-left: 25px;
}*/
.new-msg-info:hover,
.new-msg-info.active {
  border: 2px solid #38417c;
}
.new-msg-info h3 span {
  font-size: 13px;
  line-height: 17px;
  font-weight: 500;
  color: rgba(56, 65, 124, 0.5);
  float: right;
}
.new-msg-info-inner {
  position: relative;
}
/*.new-msg-dots h3{
    position: relative;
  }*/
/*.new-msg-dots h3:before{
  height: 7px;
  width: 7px;
  display: inline-block;
  vertical-align: middle;
  background: #38417C;
  border-radius: 100%;
  position: absolute;
  left: -15px;
  top: 4px;
  content: "";
}*/
.chat-window .chat-text {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.chat-window .type-msg .common-input {
  border: 1px solid #d2d4de;
  border-radius: 8px;
  font-size: 18px;
  color: rgba(56, 65, 124, 0.6);
  padding: 10px 20px;
}
.chat-window .type-msg {
  bottom: 20px;
  width: calc(100% - 40px);
  left: 10px;
}
.chat-window .type-msg.with-close form {
  width: calc(100% - 100px);
  float: left;
}
/*** custom scrollbar css start ***/
.custom-scrl-content {
  overflow: auto;
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 518px;
}
.custom-scrl-content.hidden {
  display: none;
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: rgba(56, 65, 124, 0.3) !important;
  border-radius: 5px !important;
  width: 8px !important;
  height: 152px;
}
.mCSB_scrollTools .mCSB_draggerRail {
  background-color: rgba(56, 65, 124, 0.1) !important;
  border-radius: 0 !important;
  width: 8px !important;
}
.mCSB_scrollTools {
  right: -3px;
  top: 0;
}
/*** custom scrollbar css end ***/
.mCSB_container {
  overflow: hidden;
  width: auto;
  height: auto;
  padding: 0px 18px 0 15px;
  margin-right: 0 !important;
}
.new-msg-btn {
  padding: 0 18px 0 15px;
}
.inbox-msgs {
  margin-top: 12px;
}
.inbox-modal .modal-backdrop.show {
  opacity: 1;
}
.inbox-modal .modal-backdrop {
  background-color: rgba(52, 54, 66, 0.95);
}
.new-message-modal .modal-content {
  padding: 40px 20px 20px;
  border-radius: 8px;
}
.new-message-modal .modal-content .modal-body,
.new-message-modal .modal-content .modal-footer,
.new-message-modal .modal-content .modal-header {
  padding: 0;
}
.new-message-modal .modal-footer .btn {
  width: 100%;
  font-size: 24px;
  height: 60px;
  max-width: 100%;
}
.new-message-modal .close {
  opacity: 1 !important;
  height: 36px;
  width: 36px;
  border-radius: 100%;
  padding: 0 !important;
  margin: 0;
  position: absolute;
  right: 20px;
  top: 40px;
}
.new-message-modal .modal-content .modal-header .modal-title {
  font-size: 36px;
  line-height: 36px;
  color: #343642;
  letter-spacing: -0.9px;
}
.new-message-modal .modal-dialog {
  max-width: 360px;
}
.new-message-modal .modal-content .modal-footer {
  padding-top: 13px;
}
.new-message-modal .modal-body form .form-group label {
  font-size: 13px;
  line-height: 17px;
  color: rgba(56, 65, 124, 0.5);
  display: block;
  margin-bottom: 5px;
}
.new-message-modal .modal-body form .form-group {
  background: #ffffff;
  border: 1px solid #d2d4de;
  border-radius: 8px;
  padding: 8px 15px;
  box-shadow: 0px 0px 14px rgba(56, 65, 124, 0.17);
  margin-bottom: 12px;
}
.new-message-modal .modal-body form input[type="text"] {
  width: 100%;
  color: #38417c;
}
.new-message-modal form input::placeholder,
.new-message-modal form textarea::placeholder {
  opacity: 1 !important;
}
.new-message-modal .modal-body form textarea {
  border: 0;
  width: 100%;
  resize: none;
  color: #73799f;
  font-size: 19px;
  min-height: 316px;
  padding-top: 15px;
  background: transparent !important;
}
.modal-footer .btn:focus {
  box-shadow: none;
}
.show-mobile {
  display: none !important;
}
.cancel-wrapper.message-window {
  display: none !important;
}
/*version 2 end*/

/*version 3*/
/*** appointments page css start ***/
.appointment-rght {
  padding-left: 5px;
}
.app-details-main {
  margin-top: 47px;
}
.app-details {
  background: #ffffff;
  box-shadow: 0px 2px 14px rgba(39, 40, 42, 0.17);
  border-radius: 8px;
  padding: 10px 28px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.app-btn .primary-btn {
  max-width: 100%;
  box-shadow: 0px 2px 14px rgba(39, 40, 42, 0.17);
}
.app-details-main h5 {
  color: rgba(56, 65, 124, 0.7);
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 18px;
}
.app-date {
  font-weight: normal;
  font-size: 38px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.6px;
  color: #38417c;
  float: left;
  border-right: 1px solid rgba(56, 65, 124, 0.3);
  /* width: 91px; */
  margin-right: 30px;
  padding: 12px 30px 12px 0;
}
.app-date span {
  display: block;
  text-align: center;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #38417c;
  opacity: 0.5;
  margin-top: 10px;
}
.app-info h2 {
  margin-bottom: 5px;
  color: #38417c;
  /* font-family: ProximaSoft; */
}
.app-info p {
  margin: 0;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: -0.375px;
  color: rgba(56, 65, 124, 0.8);
}
.app-info {
  padding: 15px 0;
  display: table;
}
.app-info.pending p {
  background: url(../images/info.svg) no-repeat left center;
  padding: 2px 0 1px 30px;
}
/*** appointments select time page css start ***/
.appointments-page .triage-app-left {
  width: 100%;
}
.no-gutter {
  margin: 0;
}
.appointments-page .no-gutter {
  width: 100%;
}
.left-col-app {
  max-width: 451px;
  width: 100%;
}
.appointment-box h2 {
  color: #38417c;
  float: left;
  text-align: left;
  width: 50%;
}
.right-col-main .appointment-box .symptoms-listing {
  padding: 0 0 0 40px;
}
.right-col-main .appointment-box {
  min-height: auto;
}
.right-col-main .next-step .primary-btn {
  max-width: 315px;
  text-transform: unset;
}
.remove-app .primary-btn {
  max-width: 388px;
  box-shadow: 0px 2px 14px rgba(39, 40, 42, 0.17);
}
.declined-app h2 {
  color: #38417c;
  margin-bottom: 13px;
}
.declined-app h3 {
  color: rgba(56, 65, 124, 0.8);
}
.declined-app {
  margin-top: 20px;
}
.declined-app-main {
  margin: 55px 0 50px 0;
}
.modal-footer form {
  width: 100%;
}
/*** appointment confirmed page css start ***/
.enter-waiting-room-btn span {
  background: url(../images/camera.svg) no-repeat left center;
  padding: 5px 0 0 33px;
}
.enter-waiting-room-btn:hover span,
.enter-waiting-room-btn:focus span {
  background: url(../images/camera-blue.svg) no-repeat left center;
}
.confirmed-appointment h2 {
  color: rgba(56, 65, 124, 0.8);
  margin-bottom: 30px;
}
.reschedule-btns {
  position: absolute;
  bottom: 110px;
  left: 0;
}
.confirmed-appointment {
  height: calc(100vh - 137px);
}
.reschedule-btns li {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}
.reschedule-btns li:first-child {
  margin-left: 0;
}
.reschedule-btns li a {
  min-width: 220px;
  box-shadow: 0px 2px 14px rgba(39, 40, 42, 0.17);
}
.reschedule-btns .secondary-btn.border-btn {
  border: 1px solid #38417c;
  color: #38417c;
  background: #fff;
}
.reschedule-btns .secondary-btn.border-btn:hover,
.reschedule-btns .secondary-btn.border-btn:focus {
  color: #fff;
  background: #38417c;
}
.confirmed-app-inner {
  padding-bottom: 150px;
}
.reschedule-btns .primary-btn {
  background: #3a3f5a;
}
.reschedule-btns .primary-btn:hover,
.reschedule-btns .primary-btn:focus {
  background: transparent;
  color: #3a3f5a;
}
.time-slot-btn li {
  float: left;
  padding-left: 10px;
  width: 50%;
}
.time-slot-btn li:first-child {
  padding-left: 0;
}
.time-slot-btn .btn {
  float: left;
  font-size: 18px;
  height: 38px;
  width: 100%;
}
.time-slot-btn .border-btn {
  border: 1px solid #38417c;
}
.time-slot-btn .border-btn:hover,
.time-slot-btn .border-btn:focus {
  background: #38417c;
  color: #fff;
}
#time-office-modal .modal-body p {
  margin-top: 5px;
}
.next-step {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
/*** triage select times page css start ***/
.days li {
  display: inline;
  margin-left: 25px;
}
.days li:first-child {
  margin-left: 0;
}
.days {
  text-align: left;
  margin-bottom: 35px;
}
.days li a {
  height: 66px;
  width: 66px;
  font-size: 24px;
  letter-spacing: -0.5px;
  color: #fff;
  background: #38417c;
  border-radius: 100%;
  display: inline-block;
  line-height: 68px;
  text-align: center;
}
.days li.day-selected a {
  color: rgba(56, 65, 124, 0.6);
  background: rgba(56, 65, 124, 0.1);
}
.symptoms-listing.with-day-selection li {
  float: left;
  width: 50%;
  margin: 13px 0 0 0;
}
.right-col-main .symptoms-listing.with-day-selection {
  width: 100%;
  padding: 0;
}
.appointment-box.appointment-with-days {
  padding: 65px 75px 65px 75px;
}
.right-col-main .next-step .primary-btn.small {
  max-width: 184px;
}
.appointment-box.appointment-with-days {
  padding: 55px 60px 55px 60px;
}
/*** triage video chat page css start ***/
.triage-video-chat-box .schedule-appointment {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.45px;
  color: #38417c;
  margin-bottom: 12px;
  border: 1px solid rgba(56, 65, 124, 0.17);
  padding: 22px 10px;
}
.triage-video-chat-box .schedule-appointment:hover,
.triage-video-chat-box .schedule-appointment:focus {
  background: #38417c;
  color: #fff;
}
.appointment-box.triage-video-chat-box {
  padding: 50px 40px 38px;
}
/*** triage confirmation page css start ***/
.view-app.primary-btn {
  box-shadow: 0px 2px 16px rgba(39, 40, 42, 0.118608);
  margin-top: -15px;
}
.circle-img-wrppaer.full-oval img {
  margin: 0 auto;
  display: block;
}
.circle-img-wrppaer.full-oval {
  left: 0;
  margin: 0 auto;
  margin-left: 83px;
}
.man-img.big-corner-img {
  max-width: 830px;
}
/*** bulletin page css start ***/
.bulletin-post {
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(56, 65, 124, 0.2);
  border-radius: 12px;
  padding: 30px;
  margin-bottom: 20px;
}
.bulletin-post h2 {
  color: #38417c;
  margin-bottom: 0;
}
.bulletin-post p {
  font-size: 15px;
  line-height: 17px;
  color: #38417c;
  margin: 15px 0 0 0;
}
.post-date {
  font-size: 13px;
  line-height: 17px;
  color: rgba(56, 65, 124, 0.7);
  margin: 12px 0 0 0;
  display: block;
}
.bulletin-post-main {
  margin: 10px 0px;
}
.bulletin-post p a {
  display: block;
}
/*version 3 end*/

/*version 4*/

.version4 .menu_wrapper .drop-down .selected a span {
  font-weight: 500;
  color: rgba(56, 65, 124, 0.84);
  background: url(../images/arrow.svg) no-repeat right;
  padding-right: 28px;
}
.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  height: 72px;
  padding: 25px 15px;
}

.form-label-group > label {
  position: absolute;
  text-align: left;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  transition: all 0.1s ease-in-out;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  letter-spacing: -0.25px;
  color: #73799f;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 14px;
  color: rgba(56, 65, 124, 0.7);
}
/* Fallback for Edge
  -------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
  -------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}
.meet-form-main {
  height: calc(100% - 83px);
  padding: 0;
}
.white-box {
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(56, 65, 124, 0.35);
  border-radius: 8px;
}
.meet-form {
  padding: 25px 20px 20px 20px;
  max-width: 362px;
  margin: -100px auto 0;
  width: 322px;
}
.meet-form h3 {
  font-family: "EuclidFlex-Medium";
  font-size: 36px;
  line-height: 38px;
  text-align: center;
  letter-spacing: -0.9px;
  color: #38417c;
}
.version4 .fild-row .selected a {
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #73799f;
  padding: 15px;
}
.version4 .fild-row .open-list .selected a {
  color: #38417c;
}
.options ul {
  top: -4px;
  overflow: hidden;
}
.primary-btn {
  background: #38417c;
  border-radius: 12px;
  height: 70px;
  letter-spacing: -0.25px;
  font-family: "EuclidFlex-Medium";
  cursor: pointer;
}
.meet-form .primary-btn {
  margin-top: 12px;
}
.form-control {
  font-size: 19px;
  color: #38417c;
  border: 1px solid #d1d4e3;
  border-radius: 8px;
}
.fild-row .options ul li a {
  font-size: 19px;
  line-height: 18px;
  padding: 19px;
  border-top: 1px solid #bfc2d6;
}
.video-mute,
.speaker-mute,
.speaker-unmute,
.video-unmute,
.call-option-img {
  background: url(../images/video-mute.svg) no-repeat;
  width: 60px;
  display: block;
  height: 60px;
  background-size: 100%;
}
.speaker-mute {
  background: url(../images/mute.svg) no-repeat;
}
.end-call {
  background: url(../images/end-call.svg) no-repeat;
}
.video-unmute {
  background: url(../images/video-unmute.svg) no-repeat;
  position: absolute;
  top: 0;
  left: 0px;
  display: block;
  width: 60px;
  height: 60px;
  opacity: 0;
  transition: 0.4s;
}
.speaker-unmute {
  background: url(../images/unmute.svg) no-repeat;
  position: absolute;
  top: -9px;
  left: -13px;
  height: 86px;
  width: 86px;
  display: block;
  background-size: 100%;
  opacity: 0;
  transition: 0.4s;
}
.mute-unmute-video .video-mute {
  opacity: 0;
}
.call-options-main {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 30px;
  text-align: center;
}
.call-options-main li {
  display: inline-block;
  margin: 0 8px;
  position: relative;
}
.call-settings {
  position: absolute;
  right: 20px;
  bottom: -10px;
}
.setting {
  background: url(../images/settings.svg) no-repeat;
  height: 80px;
  width: 80px;
  display: block;
  background-size: 100%;
  z-index: 9;
  position: relative;
}
.setting-info {
  width: 175px;
  height: 40px;
  background: #192344;
  border-radius: 8px;
  font-family: "EuclidFlex-Regular";
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.45px;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  position: absolute;
  opacity: 0;
  right: 0;
  bottom: 90px;
  transition: 0.4s;
}
.open-setting .setting-info {
  opacity: 1;
}
.fild-row .options {
  position: relative;
}
.mute-unmute .speaker-unmute,
.mute-unmute-video .video-unmute {
  opacity: 1;
}
#m-code.code-apply {
  background: url(../images/code-arrow.svg) no-repeat 97% center;
}
/*** meet page css end ***/
/*** video activate page css start ***/
.video-bg,
.request-submitted {
  background-size: cover;
  height: calc(100% - 83px);
}
/*** request submitted page css start ***/
.call-left {
  float: left;
  width: 73%;
  position: relative;
}
.call-img {
  background-repeat: no-repeat;
  background-position: top center;
  height: calc(100vh - 96px);
  background-size: cover;
}
.call-options-main .volume-icon {
  position: absolute;
  left: 50px;
  top: 23px;
}
.volume-icon > span {
  font-size: 14px;
  letter-spacing: -0.45px;
  color: #fff;
  font-family: "EuclidFlex-Regular";
  background: url(../images/volume.svg) no-repeat right center;
  padding: 10px 30px 10px 0;
}
.call-right {
  float: right;
  width: 27%;
  position: relative;
}
.call-img-main {
  position: relative;
  margin-top: 16px;
  border: 2px solid transparent;
  border-radius: 12px;
  overflow: hidden;
}
.call-img-main.active {
  border: 2px solid #38417c;
}
.user-call-info-main {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 20px;
  padding: 0 20px 0 20px;
}
.usname {
  font-size: 14px;
  letter-spacing: -0.45px;
  color: #fff;
  font-family: "EuclidFlex-Regular";
  float: left;
}
.user-call-info ul li {
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  margin-left: 7px;
}
.user-call-info ul li:first-child {
  margin-left: 0;
}
.user-call-info .video-m,
.user-call-info .speaker-mute {
  height: 23px;
  width: 23px;
  background-size: 100%;
}
.user-call-info .speaker-unmute {
  height: 32px;
  width: 32px;
  background-size: 100%;
  top: -3px;
  left: -5px;
}
.user-call-info {
  float: right;
}
.call-info-tabs .nav-tabs {
  border-bottom: 0;
  justify-content: center;
}
.vol-imgs img {
  vertical-align: middle;
}
.call-info-tabs .tab-content {
  padding: 0 20px 10px 12px;
}
.call-img-main > div > img {
  width: 100%;
  border-radius: 8px;
}
.call-info-tabs .nav-tabs .nav-item.show .nav-link,
.call-info-tabs .nav-tabs .nav-link.active {
  color: #343642;
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #38417c;
}
.call-info-tabs .nav-tabs .nav-link {
  font-family: "EuclidFlex-Medium";
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.45px;
  color: rgba(56, 65, 124, 0.84);
  border: 0;
  border-bottom: 2px solid transparent;
  text-transform: unset;
  padding: 18px 0 12px 0;
}
.call-info-tabs .nav-tabs .nav-item {
  float: left;
  width: 50%;
  text-align: center;
}
.users-img {
  float: left;
  width: 32px;
  height: 32px;
  border-radius: 100%;
}
.name-user {
  background: #ffc700;
  font-family: "EuclidFlex-Regular";
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.45px;
  color: #ffffff;
  text-transform: uppercase;
}
.user-chatting {
  display: table;
  padding-left: 10px;
}
.user-chatting h5 {
  color: rgba(25, 35, 68, 0.6);
  font-size: 14px;
  font-family: "EuclidFlex-Regular";
}
.user-chatting p {
  color: #343642;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  font-family: "ProximaSoft-Regular";
}
.user-chatting p a {
  clear: both;
  color: #ffc700;
  text-decoration: underline;
}
.user-chat-main {
  margin-bottom: 20px;
}
#chat {
  padding-top: 50px;
}
.call-right .type-msg {
  width: calc(100% - 40px);
  left: 20px;
}
.call-right .type-msg .common-input {
  width: calc(100% - 126px);
  height: 47px;
  border: 1px solid #d2d4de;
  padding: 14px;
}
.call-right .type-msg .primary-btn {
  float: right;
  width: 106px;
  font-size: 22px;
  height: 47px;
}
.call-right .tab-content > .active {
  opacity: 1;
}
/*** joining calls page css start ***/
.joining-calls-main {
  position: absolute;
  left: 0;
  bottom: 0;
  height: calc(100% - 83px);
  z-index: 9999;
  background: rgba(25, 35, 68, 0.9);
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.joining-loading h6 {
  color: #fff;
  font-family: "EuclidFlex-Medium";
  font-size: 22px;
  align-items: center;
  letter-spacing: -0.45px;
  color: #ffffff;
}
/*** inthis page css start ***/
.caller-main {
  position: absolute;
  right: 110px;
  bottom: 10px;
}
.call-info-tabs .nav-tabs .nav-link span {
  position: relative;
}
.call-info-tabs .nav-tabs .nav-link span:before {
  position: absolute;
  width: 7px;
  height: 7px;
  right: -12px;
  top: 0px;
  background: #ff7259;
  border-radius: 100%;
  content: "";
}
.user-chat-main.more-space {
  margin-bottom: 100px;
}
.call-right .custom-scrl-content {
  height: calc(100vh - 132px);
}
.call-right .mCSB_container {
  padding-right: 0;
}
/*end version 4*/
.navbar-header figure {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #38417c;
}
.navbar-header figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.menu_wrapper button span {
  width: 35px;
  margin: 5px;
  display: block;
  height: 3px;
  background: #38417c;
  transition: 0.4s;
  border-radius: 83px;
  overflow: hidden;
}
.menu_wrapper button[aria-expanded="true"] span:first-of-type {
  transform: rotate(45deg) translate(11px);
}
.menu_wrapper button[aria-expanded="true"] span:nth-of-type(2) {
  opacity: 0;
}
.menu_wrapper button[aria-expanded="true"] span:last-of-type {
  transform: rotate(-45deg) translate(12px);
}
.main-bg-sectoin .container .row {
  justify-content: center;
}
.main-bg-sectoin.landing-page.landing-content .container .row > div {
  text-align: center;
}
.main-bg-sectoin.landing-page.landing-content .container .row .primary-btn {
  margin: 0 auto;
}

.main-bg-sectoin.select-visitor .row,
.main-bg-sectoin.left .row {
  justify-content: left;
}
.main-bg-sectoin.landing-page.landing-content .container .row > div {
  text-align: left;
}
.password {
  position: relative;
}
.password span {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #73799f;
}
.visited-list {
  background-color: #fff;
  padding: 14px 20px;
  border-radius: 12px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 12px rgba(56, 65, 124, 0.2);
  position: relative;
  z-index: 5;
}
.visited-list .profile {
  height: 52px;
  width: 52px;
  border-radius: 50%;
}
.visited-list .profile img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.visited-list h2 {
  margin-bottom: 0;
  letter-spacing: -0.25px;
  color: #343642;
  font-weight: 500;
  font-size: 24px;
  padding-left: 20px;
}

.visited-list .details {
  width: calc(100% - 52px);
}
.visited-list .details span {
  color: #73799f;
  font-family: "ProximaSoft-Regular";
  font-size: 14px;
}

.some-one-else h2 {
  color: #73799f;
}
.some-one-else span {
  cursor: pointer;
}
.who-visited h1 {
  font-size: 55px;
  line-height: 55px;
}
.main-bg-sectoin .main {
  padding-left: 100px;
  width: 100%;
}
.office-home-info .counter {
  position: static;
  text-align: center;
}
.name-designation.office-home-heading {
  margin-top: 30px;
}
.appointment .yellow-sqr,
.icon-small {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  min-width: 36px;
  margin-right: 10px;
}
.appointment .yellow-sqr img,
.icon-small img {
  width: 21px;
}

.app-wrapper {
  box-shadow: 0px 0px 32px rgba(56, 65, 124, 0.3);
  border-radius: 12px;
  background: #ffffff;
  padding: 40px;
}
.app-wrapper > h2 {
  font-family: "EuclidFlexMedium";
  font-weight: 500;
  font-size: 36px;
  color: #343642;
  margin-bottom: 42px;
}
.app-wrapper .profile {
  height: 64px;
  width: 64px;
}
.app-wrapper .visited-list h2 {
  padding-left: 0px;
  text-align: left;
  font-family: "EuclidFlexMedium";
}
.app-wrapper .visited-list .details > div {
  padding-left: 20px;
}
.bullet-spac {
  max-height: 400px;
}
.dot .profile {
  position: relative;
}
.dot .profile:before {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #6dcdb8;
  border: 3px solid #ffffff;
  right: -5px;
  bottom: 0;
}
.visited-list .details span .fa.fa-plus {
  font-size: 22px;
}
.app-wrapper .visited-list p {
  text-align: left;
}
.tra-waiting .primary-btn {
  max-width: 100%;
}
.tra-waiting .triage-details {
  position: relative;
}
.tra-waiting .triage-details .form-control {
  padding-bottom: 45px;
}
.tra-waiting .word-limit {
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.tra-waiting .appointment-box h2 {
  width: 100%;
  font-size: 36px;
  font-family: "EuclidFlexMedium";
  letter-spacing: -0.9px;
  margin-bottom: 30px;
  line-height: 36px;
}
.tra-waiting .appointment-box textarea {
  color: #343642;
  background-color: #eef0fc;
}
.tra-waiting .appointment-box {
  background: #ffff;
  box-shadow: 0px 2px 14px rgba(39, 40, 42, 0.17);
}

.user-detail-popup li:first-child {
  display: flex;
  flex-direction: column;
}
.user-detail-popup li:first-child {
  border: 0;
}
.user-detail-popup li:last-child {
  border: 0;
}
.user-detail-popup li {
  border-top: none;
  border-bottom: 1px solid #d1d4e3;
}
.user-detail-popup li:first-child img {
  height: 80px;
  width: 80px;
  border: 3px solid #38417c;
  object-fit: cover;
  border-radius: 50%;
}
.user-detail-popup {
  margin-top: 29px;
}
.user-detail-popup li:first-child h2 {
  padding-left: 0px;
  margin-top: 20px;
  font-size: 36px;
  line-height: 40px;
  font-family: "EuclidFlexMedium";
  text-transform: capitalize;
  margin-bottom: 20px;
}
.user-detail-popup li a {
  font-family: "EuclidFlexMedium";
  position: relative;
  color: #343642 !important;
  font-weight: 500;
  font-size: 24px;
  padding: 27px 0px;
}
.user-detail-popup li:first-child a:after {
  content: none;
}
.user-detail-popup li a:after {
  content: "";
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url(../images/Path.png);
  height: 12px;
  width: 8px;
  background-repeat: no-repeat;
}
.head figure {
  overflow: hidden;
  height: 48px;
  width: 48px;
}
.head figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.head h2 {
  font-family: "EuclidFlexMedium";
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-left: 15px;
}
.bulletin-post p {
  font-size: 19px;
  color: #343642;
  line-height: 23px;
  font-weight: 500;
}
.bulletin-post .post-date {
  font-size: 14px;
  line-height: 16px;
}

.landing-page .title h1 {
  margin: 0 auto;
  padding-bottom: 60px;
  font-size: 55px;
  max-width: 550px;
}
.form-control:focus {
  color: #38417c;
}
.form-control::placeholder {
  color: #73799f;
}
.toggle-password {
  cursor: pointer;
}
.toggle-password .show {
  display: none;
}
.toggle-password.show .show {
  display: block;
}
.toggle-password.show .hide {
  display: none;
}
.d-mobile {
  display: none;
}
.chat-main {
  box-shadow: 0px 0px 32px rgba(56, 65, 124, 0.3);
  background: #ffffff;
  padding: 20px 5px;
  border-radius: 12px;
}
.chat-main .chat-window {
  box-shadow: none;
  border-radius: 0;
  background: transparent;
  padding: 0 30px 0 10px;
}
.chat-main .new-msg-btn > a {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}
.chat-main .new-msg-btn > a:hover,
.chat-main .new-msg-btn > a:focus {
  background-color: #38417c;
  color: #fff;
  opacity: 0.9;
}
.chat-main .new-msg-info h3 img {
  height: 26px;
  width: 26px;
  object-fit: cover;
  object-position: center center;
  border-radius: 50%;
  margin-right: 9px;
}
.chat-main .new-msg-info h3 {
  line-height: 21px;
  font-family: "EuclidFlexMedium";
  font-weight: 500;
  color: #343642;
  letter-spacing: -0.25px;
  margin-bottom: 0;
  display: block;
  white-space: nowrap;
  width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
}
.chat-main .new-msg-info.private h3 {
  width: calc(100% - 90px);
}
.chat-main .new-msg-info h3 span.counter {
  font-size: 15px;
  height: 21px;
  width: 21px;
  font-family: "ProximaSoft-Regular";
  position: absolute;
  right: 0;
  top: 0;
}
.chat-main .new-msg-info p {
  padding-left: 35px;
  color: #73799f;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-top: 0;
  padding-right: 24px;
}
.chat-main span.pr-wrap {
  width: 55px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d1d4e3;
  color: #343642 !important;
  border-radius: 4px;
  position: absolute;
  right: 28px;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: -0.25px;
  font-family: "ProximaSoft-Regular";
  top: 0;
}
.private-rt {
  font-weight: 500;
  font-size: 14px;
  color: #343642;
  letter-spacing: -0.25px;
  font-family: "ProximaSoft-Regular";
  background: #d1d4e3;
  display: inline-block;
  padding: 12px 10px;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.chat-main .chat-window h2 {
  color: #343642;
  font-weight: 500;
  font-family: "EuclidFlex-Medium";
  margin-top: 8px;
  letter-spacing: -0.25px;
}
.chat-main .chat-content {
  position: relative;
}
.chat-main .chat-toggles {
  position: absolute;
  right: 0;
  top: 0;
}
.chat-text:nth-child(2n) .chat-info {
  position: relative;
  margin-bottom: 5px;
}
.chat-text:nth-child(2n) .chat-info:before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: -25px;
  right: 0;
  content: "";
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.0001) 100%
  );
}

.chat-main .chat-user {
  height: 32px;
  width: 32px;
}
.chat-main .chat-user img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.chat-main .chat-info {
  margin-bottom: 5px;
}
.chat-box p {
  font-size: 19px;
  line-height: 23px;
  letter-spacing: -0.25px;
  margin-bottom: 0;
  text-align: left;
}
.type-msg .common-input {
  height: 44px;
  box-shadow: none;
}
.type-msg .primary-btn {
  height: 44px;
  width: 70px;
  font-size: 18px;
  max-width: none;
  float: right;
  border-radius: 8px;
}
.chat-window .type-msg .common-input {
  box-shadow: none;
  font-size: 19px;
}

/*waiting room*/
.waiting-room {
  padding-top: 150px;
}
.doc-details figure {
  height: 104px;
  width: 104px;
  overflow: hidden;
}
.doc-details figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.primary-btn.orange {
  background: #ff5959;
  color: #fff;
  max-width: 211px;
  min-width: 211px;
  height: 70px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}
.primary-btn.orange:hover {
  border-color: #ff5959;
  background: #fff;
  color: #ff5959;
}
.primary-btn.orange.m-btn {
  background: #ff5959;
  color: #fff;
  max-width: 106px;
  min-width: 106px;
  height: 60px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 18px;
  line-height: 60px;
}
.bottom-btn {
  position: absolute;
  bottom: 50px;
  width: 100%;
}
.doc-details h1 {
  font-weight: 500;
  font-size: 36px;
  line-height: 38px;
  max-width: 388px;
  margin: 44px auto 0px auto;
  text-transform: unset;
  color: #38417c;
  text-align: center;
  letter-spacing: -0.9px;
}
.doc-details h1 span {
  display: block;
}
.doc-details p {
  color: #73799f;
  text-align: center;
  letter-spacing: -0.25px;
  font-size: 19px;
}
.b-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.b-wrapper .right {
  display: flex;
  align-items: center;
}
.b-wrapper .right a {
  width: 64px;
  background: #ffffff;
  height: 64px;
  box-shadow: 0px 0px 12px rgba(56, 65, 124, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  border-radius: 50%;
  cursor: pointer;
}
.b-wrapper .right a:hover {
  transform: scale(1.1);
}
.model-chat .waiting-content {
  width: 100%;
  padding-left: 10px;
  background: transparent;
  border-radius: 14px;
  box-shadow: none;
  float: left;
  padding: 0;
  position: relative;
}
.model-chat .type-msg {
  position: static;
}
.model-chat .chat-text:nth-child(1n) .chat-info {
  text-align: right;
  position: relative;
  padding-top: 0px;
}
.model-chat .chat-text:nth-child(1n) .chat-user {
  float: right;
}
.model-chat .chat-text:nth-child(1n) .chat-info:before {
  position: absolute;
  left: 0px;
  top: 5px;
  bottom: -40px;
  right: 0;
  content: "";
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.0001) 100%
  );
}
.model-chat .chat-text:nth-child(2n) .chat-info:before {
  content: none;
}
.model-chat .chat-box {
  background-color: #38417c;
  width: 94%;
  margin-left: auto;
}
.model-chat .chat-box p {
  color: #fff;
}
.model-chat .chat-time {
  text-align: right;
  display: block;
}
.new-message-modal .modal-content .modal-footer {
  padding-top: 0;
}
.model-chat .chat-text:nth-child(2n) .chat-info {
  border: 0;
  text-align: left;
}
.new-message-modal .modal-dialog {
  max-width: 440px;
}
.model-chat .chat-text:nth-child(even) .chat-box {
  max-width: 94%;
  margin-left: 0;
  margin-right: 0;
  background: #eff1f9;
}
.model-chat .chat-text:nth-child(even) .chat-box p {
  color: #38417c;
}
.model-chat .chat-text:nth-child(even) .chat-time {
  text-align: left;
}
.model-chat .chat-text:nth-child(even) .chat-user {
  float: left !important;
}
.model-chat .type-msg form {
  display: flex;
}
.model-chat .type-msg {
  width: 100%;
}
.model-chat .type-msg .primary-btn {
  margin-left: 10px;
}
.model-chat .type-msg .common-input {
  height: 60px;
  color: rgba(56, 65, 124, 0.8) !important;
}
.model-chat .type-msg .primary-btn {
  height: 60px;
  width: 110px;
  min-width: 110px;
  font-size: 24px;
  line-height: 22px;
}
.join-appointment {
  text-align: center;
  padding: 20px;
  background: #eef0fc;
  border-radius: 12px;
  margin-top: 25px;
  max-width: 94%;
}
.join-appointment h3 {
  font-size: 19px;
  line-height: 23px;
  letter-spacing: -0.25px;
  color: #38417c;
  margin-bottom: 12px;
}
/* Edit profile start */
.profile-edit-main .profile-edit {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.profile-edit-main .profileImg {
  max-width: 115px;
  height: 115px;
  border-radius: 100%;
  width: 100%;
  background: #eef0fc;
}
.profile-edit-main .btns {
  width: 100%;
  padding-left: 45px;
}
.profile-edit-main .btns .btn:not(:last-child) {
  margin-right: 20px;
  height: auto;
}
.profile-edit-main .btns .btn:not(:first-child) {
  color: #343642;
  font-weight: 600;
  background-color: #eef0fc;
  border: transparent;
}
.edit-form .edit-row {
  text-align: left;
  margin-left: 160px;
  margin-bottom: 35px;
}
.edit-form .edit-row h3 {
  text-transform: uppercase;
  margin-bottom: 25px;
  letter-spacing: 1.25px;
}
/* Edit profile end */
/* My Offices start */
.myOffice-page .accordion {
  padding: 0;
  text-align: left;
}
.myOffice-page .accordion .box-profile {
  margin-left: 20px;
}
.myOffice-page .accordion .box-profile h2 {
  width: auto;
  float: none;
  color: #343642;
  font-family: "EuclidFlex-Medium";
}
.myOffice-page .accordion .card.open-accordion {
  border: 1px solid transparent !important;
}
.myOffice-page .accordion .card-body {
  display: flex;
}
.myOffice-page .accordion .card-body ul {
  width: 100%;
}
.myOffice-page .accordion ul li small {
  text-transform: uppercase;
  color: #38417c;
  font-size: 17px;
}
.myOffice-page .accordion .small-text span {
  display: block;
  color: #38417c;
}
.myOffice-page .accordion .card-body .btns {
  width: 100%;
}
.myOffice-page .accordion .card-body .btns .btn {
  background: #38417c;
  border-radius: 12px;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  padding: 6px 36px;
  margin-left: 10px;
}
.myOffice-page .accordion .card-body .btns .btn:not(:first-child) {
  background: #ff5959;
}
.add-new-off-head h2 {
  color: #343642;
  font-family: "EuclidFlex-Medium";
}
.myOffice-page .appointment-box {
  padding: 30px;
}
.dots.red {
  background: #ff5959;
}
#accordionEx .card-wrapper .counter {
  position: absolute;
  right: 35px;
  top: auto;
}
.myOffice-page .accordion .card-header a {
  background: url(../images/select-arrow-open.svg) no-repeat right;
}
.myOffice-page .accordion .card-header a.collapsed {
  background: url(../images/arrow-grey.png) no-repeat right;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.myOffice-page .accordion .card {
  padding: 14px 20px;
}
.myOffice-page .visited-list {
  padding: 24px 20px;
}
/* My Offices end */
/*** new message popup start ***/
#new-message-modal .fild-row,
#setting-popup .fild-row {
  margin-bottom: 1rem;
}
#setting-popup .modal-header,
.inbox-page-popup .modal-header {
  margin-bottom: 40px;
}
.open-list-multiple .options ul {
  display: block;
}
/*** new message popup end ***/
/*** setting popup start ***/
#setting-popup .fild-row .selected a {
  font: 14px;
}
#setting-popup .fild-row .selected a span {
  font-size: 19px;
  color: #38417c;
}
#setting-popup .fild-row .drop-down {
  position: relative;
  border: 1px solid #d1d4e3;
  border-radius: 12px;
}
.vol {
  position: absolute;
  right: 40px;
  top: 24px;
}
#setting-popup .fild-row .selected a {
  padding: 18px 15px;
}
#setting-popup .fild-row .selected a.test-vol {
  position: absolute;
  right: 49px;
  top: 19px;
  border: 0;
  background-image: none;
  width: 64px;
  height: 32px;
  background: #38417c;
  border-radius: 5px;
  color: #fff;
  font-size: 19px;
  padding: 0;
  line-height: 33px;
  text-align: center;
}
.chat-icon {
  position: relative;
}
.msg-note {
  position: absolute;
  right: 0;
  top: 0px;
  background: #ff5959;
  height: 18px;
  width: 18px;
  border-radius: 100%;
}
.doc-details .primary-btn {
  margin: 35px 0 0 0;
}

.find-covid-btn {
  background-color: #38417c;
  height: 64px;
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 27px;
  font-size: 24px;
  font-family: "EuclidFlexMedium";
  box-shadow: 0px 0px 12px rgba(56, 65, 124, 0.2);
  border-radius: 12px;
  margin-top: 40px;
}
.find-covid-btn img {
  margin-top: 2px;
}
.find-covid-btn:hover,
.find-covid-btn:focus {
  color: #fff;
}
.find-covid h1 {
  font-size: 55px;
  line-height: 60px;
}
.find-covid .name-designation.office-home-heading {
  margin-top: 0;
}
.find-covid .content-wrapper {
  padding-left: 130px;
}
/*search page css*/
.main-bg-sectoin.search-page {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 90px);
}

.search-page h1 {
  font-size: 55px;
  line-height: 60px;
  letter-spacing: -1.5px;
  margin-bottom: 15px;
}
.search-page p {
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #343642;
  opacity: 0.9;
  letter-spacing: -0.25px;
  max-width: 70%;
  margin: 0 auto;
}
.s-box {
  margin-top: 40px;
  max-width: 380px;
  margin: 40px auto;
}
.s-box .form-control,
.s-box .input-group-text {
  background: rgba(56, 65, 124, 0.09);
  border-radius: 8px;
  border: 0;
  height: auto;
  padding: 18px 22px;
  font-family: "ProximaSoft-Regular";
  color: #343642;
  letter-spacing: -0.25px;
}
.s-box .input-group-prepend {
  margin-right: 0px;
}
.s-box .form-control {
  padding-left: 0;
}
.s-box .input-group-text img {
  max-width: 21px;
}

.search-result .s-box {
  box-shadow: 0px 0px 32px rgba(56, 65, 124, 0.3);
  border-radius: 8px;
  border: 2px solid #38417c;
  margin-top: 0;
}

.s-box .form-control::-webkit-input-placeholder {
  /* Edge */
  color: #343642;
  opacity: 0.8;
}

.s-box .form-control:-ms-input-placeholder {
  /* Internet Explorer */
  color: #343642;
  opacity: 0.8;
}

.s-box .form-control::placeholder {
  color: #343642;
  opacity: 0.8;
}
.search-result .s-box .form-control::-webkit-input-placeholder {
  /* Edge */
  color: #343642;
  opacity: 1;
}

.search-result .s-box .form-control:-ms-input-placeholder {
  /* Internet Explorer */
  color: #343642;
  opacity: 1;
}

.search-result .s-box .form-control::placeholder {
  color: #343642;
  opacity: 1;
}
.search-result .s-box .form-control,
.search-result .s-box .input-group-text {
  background-color: #fff;
}
.map-img {
  position: relative;
  min-height: 100vh;
}
.main-bg-sectoin.search-result {
  position: relative;
  z-index: 9;
}
.map-img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../images/full-map.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}
.map-img:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(56, 65, 124, 0.01) 0%,
    rgba(42, 52, 138, 0.14) 100%
  );
}
.search-list {
  margin-bottom: 30px;
}
.search-list ul {
  padding: 5px 20px;
  background: #fff;
  box-shadow: 0px 0px 12px rgba(56, 65, 124, 0.2);
  border-radius: 8px;
  max-width: 380px;
  margin: 0 auto;
}
.search-list ul li {
  padding-top: 23px;
  padding-bottom: 23px;
  border-bottom: 1px solid #d1d4e3;
}
.search-list ul li:last-child {
  border-bottom: 0px;
}
.search-list h4 {
  color: #343642;
  letter-spacing: -0.25px;
  font-weight: 500;
  font-size: 19px;
  font-family: "ProximaSoft-Regular";
}
.search-list span {
  font-family: "ProximaSoft-Regular";
  font-size: 14px;
  color: #343642;
  letter-spacing: -0.25px;
  opacity: 0.6;
}
.search-list p {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.25px;
  color: #73799f;
  margin-top: 5px;
}
.search-result .s-box {
  margin: 0px auto 20px auto;
}
.search-result .s-box .form-control {
  opacity: 1;
}
.search-result.main-bg-sectoin {
  padding: 94px 0 0 0;
}

/*steps css*/
.wizard {
  width: 100%;
}
.wizard > .steps .current-info,
.wizard > .content > .title {
  position: absolute;
  left: -99999px;
}
.wizard > .content {
  position: relative;
  width: auto;
  padding: 0;
}
.wizard > .content > .body {
  padding: 80px 0px;
}
.wizard > .content > iframe {
  border: 0 none;
  width: 100%;
  height: 100%;
}
.wizard > .steps {
  position: relative;
  display: block;
  width: 100%;
}
.wizard > .steps > ul {
  display: table;
  width: 100%;
  table-layout: fixed;
  margin: 0;
  padding: 0;
  list-style: none;
}
.wizard > .steps > ul > li {
  display: table-cell;
  width: auto;
  vertical-align: top;
  text-align: center;
  position: relative;
}
.wizard > .steps > ul > li a {
  position: relative;
  padding-top: 48px;
  margin-top: 40px;
  margin-bottom: 40px;
  display: block;
  font-size: 18px;
}
.wizard > .steps > ul > li:before,
.wizard > .steps > ul > li:after {
  content: "";
  display: block;
  position: absolute;
  top: 58px;
  width: 50%;
  height: 2px;
  background-color: #38417c;
  z-index: 9;
}
.wizard > .steps > ul > li:before {
  left: 0;
}
.wizard > .steps > ul > li:after {
  right: 0;
}
.wizard > .steps > ul > li:first-child:before,
.wizard > .steps > ul > li:last-child:after {
  content: none;
}
.wizard > .steps > ul > li.current:after,
.wizard > .steps > ul > li.current ~ li:before,
.wizard > .steps > ul > li.current ~ li:after {
  background-color: #eeeeee;
}
.wizard > .steps > ul > li.current > a {
  color: #38417c;
  cursor: default;
}
.wizard > .steps > ul > li.current .number {
  border-color: #38417c;
  background-color: #38417c;
  color: #38417c;
}
.wizard > .steps > ul > li.disabled a,
.wizard > .steps > ul > li.disabled a:hover,
.wizard > .steps > ul > li.disabled a:focus {
  color: #a5aeb7;
  cursor: default;
}
.wizard > .steps > ul > li.done a,
.wizard > .steps > ul > li.done a:hover,
.wizard > .steps > ul > li.done a:focus {
  color: #38417c;
}
.wizard > .steps > ul > li.done .number {
  font-size: 0;
  background-color: #38417c;
  border-color: #38417c;
  color: #fff;
}
.wizard > .steps > ul > li.error .number {
  border-color: #f44336;
  color: #f44336;
}
.wizard > .content > .body {
  position: relative;
}
.main-bg-sectoin.steps-main {
  padding: 0;
}
.wizard {
  padding-bottom: 100px;
}
.wizard > .steps {
  position: absolute;
  bottom: 0;
  left: -80px;
  width: calc(100% + 176px);
}
.wizard > .actions > ul > li > a[href="#finish"] {
  padding: 17px 25px 17px 25px;
}
.wizard > .steps .number {
  background-color: #fff;
  color: #a5aeb7;
  display: inline-block;
  position: absolute;
  top: 12px;
  left: 50%;
  margin-left: -6px;
  width: 13px;
  height: 13px;
  border: 3px solid #eeeeee;
  font-size: 0px;
  color: transparent;
  border-radius: 50%;
  z-index: 10;
  line-height: 13px;
  text-align: center;
}
.panel-flat > .wizard > .steps > ul {
  border-top: 1px solid #ddd;
}
.wizard > .actions {
  display: block;
  text-align: right;
  padding: 38px 0px 43px 0px;
}
.wizard > .actions > ul {
  display: flex;
  justify-content: space-between;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}
.wizard > .actions:after {
  content: "";
  position: absolute;
  height: 1px;
  background: #d1d4e3;
  left: -9999px;
  right: -9999px;
  bottom: 245px;
}

.steps-main {
  overflow-x: hidden;
}

.wizard > .actions > ul > li {
  float: left;
}
.wizard > .actions > ul > li + li {
  margin-left: 10px;
}
.wizard > .actions > ul > li > a {
  background: #38417c;
  color: #fff;
  display: block;
  padding: 10px 25px;
  border-radius: 0px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  border: 2px solid #38417c;
}
.wizard > .actions > ul > li > a {
  border-radius: 12px;
  padding: 17px 35px 17px 25px;
  font-size: 24px;
  letter-spacing: -0.25px;
  text-transform: capitalize;
  background-color: #38417c;
  border-color: #38417c;
  position: relative;
}
.wizard > .actions > ul > li > a[href="#previous"] {
  background-color: #fff;
  color: #38417c;
  border: 2px solid #d1d4e3;
  border-radius: 12px;
  padding: 17px 25px 17px 35px;
  font-size: 24px;
  letter-spacing: -0.25px;
  text-transform: capitalize;
}
.wizard > .actions > ul > li > a[href="#previous"]:before {
  content: "";
  position: absolute;
  left: 20px;
  right: 0;
  background-image: url(../images/left-arrow.svg);
  background-size: 100% 100%;
  height: 16px;
  width: 8px;
  background-repeat: no-repeat;
  top: 51%;
  transform: translateY(-50%);
}
.wizard > .actions > ul > li > a[href="#next"]:before {
  content: "";
  position: absolute;
  right: 20px;
  background-image: url(../images/right-arrow.svg);
  background-size: 100% 100%;
  height: 16px;
  width: 8px;
  background-repeat: no-repeat;
  top: 51%;
  transform: translateY(-50%);
}
.wizard > .actions > ul > li.disabled > a,
.wizard > .actions > ul > li.disabled > a:hover,
.wizard > .actions > ul > li.disabled > a:focus {
  background-color: #fff;
  color: #4a4a49;
  border: 2px solid #ededed;
}
.wizard > .actions > ul > li.disabled > a[href="#previous"],
.wizard > .actions > ul > li.disabled > a[href="#previous"]:hover,
.wizard > .actions > ul > li.disabled > a[href="#previous"]:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
label {
  display: block;
}
label.error {
  color: red;
}
/*end steps css*/

/*steps1*/
.common-step .left-side {
  max-width: 380px;

  @media (max-width: 500px) {
    // max-width: 0px;
  }
}
.common-step .right-side {
  width: calc(100% - 380px);
  padding-left: 120px;
  @media (max-width: 500px) {
    width: 100%;
    padding-left: 0px;
    margin-top: 50px;
  }
}
.map-frame {
  height: 150px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 12px rgba(56, 65, 124, 0.2);
}
.map-frame iframe {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.common-step h4 {
  color: #38417c;
  font-size: 18px;
  margin-top: 40px;
  letter-spacing: -0.25px;
}
.common-step {
  display: flex;

  @media (max-width: 500px) {
    display: block;
  }
}
.common-step h2 {
  font-size: 36px;
  line-height: 38px;
  letter-spacing: -0.9px;
  color: #343642;
  margin-top: 10px;
  font-family: "EuclidFlexMedium";
  margin-bottom: 0;
}
.nav-pills li {
  width: 50%;
}
.nav-pills li a {
  padding: 25px;
  text-align: center;
  letter-spacing: -0.25px;
  font-size: 19px;
  border: 0px;
  display: block;
  font-family: "ProximaSoft-Regular";
}
.nav-pills {
  border: 2px solid #38417c;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 43px;
}
.nav-pills li:first-child a {
  border-right: 2px solid #38417c;
}
.nav-pills li a.active {
  background: #eef0fc;
  color: #38417c;
}

.time-list {
  display: flex;
  justify-content: space-between;
}
.time-list h4 {
  text-align: center;
  letter-spacing: -0.25px;
  color: #38417c;
  font-size: 24px;
  font-family: "EuclidFlexMedium";
  margin-bottom: 30px;
  margin-top: 0;
}
.time-list > div {
  flex: 1;
  text-align: center;
  margin-right: 35px;
}
.time-list > div:last-child {
  margin-right: 0;
}
.time-list > div a {
  display: block;
  font-size: 19px;
  border: 2px solid #d1d4e3;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 12px;
  transition: 0.4s;
}
.time-list > div a:hover,
.time-list > div a:focus {
  background: #eef0fc;
  border-color: #38417c;
  color: #38417c;
}
.time-list > div a.selected {
  background: #eef0fc;
  border-color: #38417c;
}

/*calender css*/
.calendar {
  width: 400px;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
}
.ui-datepicker-header {
  height: 50px;
  line-height: 50px;
  margin-bottom: 20px;
}
.ui-datepicker-prev,
.ui-datepicker-next {
  width: 32px;
  height: 32px;
  background-color: #38417c;
  text-indent: 9999px;
  border-radius: 100%;
  cursor: pointer;
  overflow: hidden;
  margin-top: 12px;
}
.ui-datepicker-prev {
  float: left;
  margin-left: 12px;
}
.ui-datepicker-prev:after {
  transform: rotate(45deg);
  margin: -41px 0px 0px 12px;
}
.ui-datepicker-next {
  float: right;
  margin-right: 12px;
}
.ui-datepicker-next:after {
  transform: rotate(-135deg);
  margin: -41px 0px 0px 8px;
}
.ui-datepicker-prev:after,
.ui-datepicker-next:after {
  content: "";
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  border-left: 2.5px solid #ffffff;
  border-bottom: 2.5px solid #ffffff;
}
.ui-datepicker-prev:hover,
.ui-datepicker-next:hover,
.ui-datepicker-prev:hover:after,
.ui-datepicker-next:hover:after {
  /* border-color: #5ed1cc;*/
}
.ui-datepicker-title {
  text-align: center;
  font-size: 24px;
  color: #38417c;
  font-family: "EuclidFlexMedium";
  letter-spacing: -0.25px;
}
.ui-datepicker-calendar {
  width: 100%;
  text-align: center;
}
.ui-datepicker-calendar thead tr th span {
  display: block;
  width: 46px;
  color: #38417c;
  margin-bottom: 5px;
  font-size: 14px;
  margin: 0 auto 5px auto;
}
.ui-state-default {
  display: block;
  text-decoration: none;
  color: #38417c;
  line-height: 46px;
  margin: 5px;
  width: 46px;
  height: 46px;
  font-size: 19px;
  border-radius: 8px;
  letter-spacing: -0.25px;
  border: 2px solid #d1d4e3;
}
.ui-state-default:hover {
  background: rgba(0, 0, 0, 0.02);
}
.ui-state-highlight {
  color: #38417c;
}
td.ui-datepicker-other-month.ui-datepicker-unselectable.ui-state-disabled {
  position: relative;
}
td.ui-datepicker-other-month.ui-datepicker-unselectable.ui-state-disabled:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 46px;
  width: 46px;
  background-color: #d1d4e3;
  border-radius: 8px;
  opacity: 0.5;
}
.ui-state-active {
  background: #eef0fc;
  border-color: #38417c;
}
.ui-datepicker-unselectable .ui-state-default {
  color: #eee;
  border: 2px solid transparent;
}
.ui-datepicker-year {
  display: none;
}
.ui-datepicker-week-end {
  opacity: 0.3;
}
.ui-datepicker-week-end.ui-state-disabled,
th.ui-datepicker-week-end {
  opacity: 1;
}
.second {
  display: flex;
  justify-content: space-between;
}
.second .time-list {
  width: calc(100% - 400px);
}
.second .time-list > div {
  margin-left: 60px;
}

.main-calender {
  display: flex;
}
.second .time-list h4 {
  margin-top: 18px;
}
.m-only {
  display: none;
}

/*profile slide css*/
.profile .form-control,
.profile .fild-row .drop-down {
  border: 2px solid #d1d4e3;
  border-radius: 12px;
}
.profile .options ul {
  border: 1px solid #d1d4e3;
}
.profile .fild-row .selected a {
  font-size: 19px;
  color: #343642;
}

.b-lbl .form-label-group > label {
  color: #343642;
}
.b-lbl .fild-row .selected a span {
  color: #343642;
}
.profile p {
  font-size: 18px;
  color: #343642;
  opacity: 0.8;
  width: 280px;
  font-family: "EuclidFlexMedium";
}
.profile .form-label-group {
  margin-bottom: 12px;
}
.profile h2 {
  margin-top: 0;
}
.profile h3,
.screening h3 {
  font-size: 24px;
  line-height: 22px;
  color: #38417c;
  margin-bottom: 30px;
  font-family: "EuclidFlexMedium";
  letter-spacing: -0.25px;
}
.profile .note {
  font-family: "ProximaSoft-Regular";
  color: #38417c;
  letter-spacing: -0.25px;
  font-size: 14px;
  margin-top: 12px;
  display: inline-block;
}
.reminder-checkbox {
  margin-top: 25px;
  margin-bottom: 60px;
}
.reminder-checkbox span {
  color: #000000;
  font-size: 18px;
  line-height: 21px;
  font-family: "EuclidFlexMedium";
  letter-spacing: -0.25px;
  font-weight: 500;
  margin-top: -2px;
}

.reminder-checkbox .checkbox-custom + .checkbox-custom-label::before,
.reminder-checkbox .checkbox-custom:checked + .checkbox-custom-label::after {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}
.reminder-checkbox .checkbox-custom + .checkbox-custom-label::before {
  border: 2px solid #73799f;
  margin-right: 10px;
}
.reminder-checkbox.s-check.m-only {
  margin-top: 28px;
  margin-bottom: 50px;
}
/*.full ~ .first-slide.current{
  display: block;
}*/

.common-step .list li {
  display: flex;
  margin-bottom: 10px;
}
.common-step .list li div {
  display: flex;
  flex-direction: column;
}
.common-step .list li .checkbox-custom-label span {
  display: block;
}
.common-step .list li span {
  color: #000000;
  font-size: 18px;
  font-family: "EuclidFlexMedium";
  font-weight: 500;
  letter-spacing: -0.25px;
}
.common-step .list li span.description {
  font-size: 16px;
  font-weight: 500;
  font-family: "ProximaSoft-Regular";
}

.common-step .list .checkbox-custom + .checkbox-custom-label::before {
  border: 2px solid #73799f;
}

.common-step .list .checkbox-custom:checked + .checkbox-custom-label::after,
.common-step .list .checkbox-custom + .checkbox-custom-label::before {
  margin-top: 3px;
  width: 20px;
  height: 20px;
}
.common-step .list li:last-child {
  margin-bottom: 0;
}

.common-step .list .checkbox-custom:checked + .checkbox-custom-label::after {
  background: url(../images/check-mark.svg) no-repeat;
  left: 5px;
}

.common-step .list .checkbox-custom:checked + .checkbox-custom-label::before {
  border: 3px solid #38417c;
}

.screening {
  flex-direction: column;
}
.screening h2 {
  margin-top: 0;
}
.screening > div {
  display: flex;
}

.screening .radio-grp input[type="radio"] {
  display: none;
}
.screening .radio-grp > div {
  flex: 1;
}
.screening .radio-grp {
  display: flex;
  border: 2px solid #38417c;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 43px;
}
.screening .radio-grp label {
  padding: 0px 25px;
  line-height: 61px;
  text-align: center;
  letter-spacing: -0.25px;
  font-size: 19px;
  border: 0px;
  display: block;
  margin-bottom: 0;
  cursor: pointer;
  color: #38417c;
}
.screening .radio-grp input[type="radio"]:checked + label {
  background: #eef0fc;
  color: #38417c;
}
.screening .radio-grp > div:first-child label {
  border-right: 2px solid #38417c;
}
.screening textarea {
  letter-spacing: -0.25px;
  font-size: 19px;
  line-height: 23px;
  height: 187px;
  padding: 25px;
  color: rgba(56, 65, 124, 0.7);
  border: 2px solid #d1d4e3;
  border-radius: 12px;
}
.screening textarea::-webkit-input-placeholder {
  color: rgba(56, 65, 124, 0.7);
}
.screening textarea::-moz-placeholder {
  color: rgba(56, 65, 124, 0.7);
}
.screening textarea::-ms-input-placeholder {
  color: rgba(56, 65, 124, 0.7);
}
.screening textarea::placeholder {
  color: rgba(56, 65, 124, 0.7);
}
.selection.screening .radio-grp {
  border: 0;
}
.selection.screening .radio-grp label {
  border: 2px solid #d1d4e3;
  width: 280px;
  margin-right: 20px;
  border-radius: 8px;
}
.selection.screening .radio-grp input[type="radio"]:checked + label {
  border-color: #38417c;
}
.selection.screening h2 {
  margin-bottom: 40px;
  margin-top: 0;
}
.appointment-confirm {
  padding-top: 105px;
}
.appointment-confirm h1 {
  font-size: 55px;
  line-height: 60px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.appointment-confirm .title p {
  font-size: 28px;
  line-height: 32px;
  color: #343642;
  margin-top: 0;
}
.appointment-confirm .address {
  margin-top: 122px;
}
.appointment-confirm .map-frame {
  width: 544px;
  height: 314px;
}
.appointment-confirm .add-details {
  margin-left: 55px;
  width: calc(100% - 544px);
}
.appointment-confirm .add-details h3 {
  color: #38417c;
  font-family: "EuclidFlexMedium";
}
.appointment-confirm .add-details h2 {
  color: #343642;
  font-size: 36px;
  line-height: 38px;
  max-width: 300px;
  font-family: "EuclidFlexMedium";
  letter-spacing: -0.9px;
}
.appointment-confirm .add-details a.primary-btn {
  height: 65px;
  width: 263px;
}
.appointment-confirm .add-details a.primary-btn:hover,
.appointment-confirm .add-details a.primary-btn:focus {
  background-color: rgba(56, 64, 124, 0.7);
  color: #fff !important;
  border-color: rgba(56, 64, 124, 0.7);
}
.first-step h4 {
  font-family: "EuclidFlexMedium";
}

.PagesStyles_confirmation__content__3HfUb a {
  background: #38417c;
  border-radius: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 22px;
  /* or 92% */

  display: flex;
  align-items: center;
  letter-spacing: -0.25px;
  width: 40%;
  height: 60px;
  padding-left: 16px;
  color: #ffffff;
}

.soonest-date {
  height: 400px;
  overflow: scroll;
}

.description {
  position: static !important;
  background: transparent !important;
  list-style: disc !important;
  border: 0 !important;
  box-shadow: none !important;
  overflow: visible !important;
}

.description li {
  list-style: disc !important;
  font-size: 17px;
  line-height: 19px;
  letter-spacing: -0.425px;
  font-weight: normal;
  color: rgba(56, 65, 124, 0.7);
  font-weight: 500;
  margin-bottom: 4px;
}

.mapboxgl-compact:after {
  display: none;
}

.mapboxgl-ctrl {
  display: none !important;
}
